import AlertSection from '@/components/common/alert/AlertSection';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import {
  initalBlockState,
  modalBlockReducer
} from '@/components/common/modals/block-service-reducer/block-modal-reducer';
import BlockServiceReducerContainer from '@/components/common/modals/block-service-reducer/BlockServiceReducer';
import { OrganizationSchedule } from '@/components/section/organization-schedule/OrganizationSchedule';

import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetServicesCheckSchedule from '@/hooks/queries/services-api/useGetServicesCheckSchedule';
import { Fragment, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SettingsPage = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(modalBlockReducer, initalBlockState);
  const { data: organization, isError } = useGetOrganization();
  const { data: servicesCheckSchedule } = useGetServicesCheckSchedule({
    activeDays: organization?.activeDays ?? '',
    activeMonths: organization?.activeMonths ?? ''
  });

  if (!organization) {
    return (
      <div className="w-full">
        <Skeleton className="h-6" />
        <Skeleton className="h-10 mt-4" />
        <Skeleton className="mt-5 h-[20rem]" />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage />;
  }

  const defaultSchedule = {
    activeMonths: JSON.parse(organization.activeMonths),
    activeDays: JSON.parse(organization.activeDays)
  };

  return (
    <>
      <div className="w-full">
        <h2 className="text-xl font-bold">{t('sidebar.settings')}</h2>
        <div className="my-4">
          <div className="flex items-center gap-2">
            <p>{t('settings.schoolAvailability')}:</p>
            <Button
              variant={'outline'}
              onClick={() => dispatch({ type: 'OPEN_MODAL', payload: ' ' })}
            >
              {t('services.block')}
            </Button>
          </div>
        </div>
      </div>
      {servicesCheckSchedule && servicesCheckSchedule.length > 0 && (
        <AlertSection
          variant="warning"
          text={
            <>
              {t('settings.scheduleConflict')}
              {servicesCheckSchedule.map((s, index) => (
                <Fragment key={s.id}>
                  <Link
                    to={`/services/${s.id}`}
                    className="hover:underline hover:underline-offset-2"
                  >
                    {s.name}
                  </Link>
                  {index < servicesCheckSchedule.length - 1 ? ', ' : '.'}
                </Fragment>
              ))}
            </>
          }
        />
      )}
      <BlockServiceReducerContainer
        isSchool
        state={state}
        dispatch={dispatch}
      />
      <OrganizationSchedule defaultSchedule={defaultSchedule} />
      <p className="pb-4">App version: {version}</p>
    </>
  );
};

export default SettingsPage;
