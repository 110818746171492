import type { ReservationInterface } from '@/components/common/tables/tables.types';
import type { GetReservationsProps } from '@/hooks/queries/reservations-api/useGetPaginatedReservation';
import type { TDateRange } from '@/pages/protected/CalendarV1';
import { buildColumnFilterURI } from '@/utils/reservations-uri';
import { createQueryParams, QueryParams } from '@/utils/utils';
import type { ApiResponse } from 'apisauce';
import type { Api } from './api';

export type TGetReservations = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: ReservationInterface[];
};

type TGetReservationsWithPendingActions = {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: ReservationWithAction[];
};

type TReservationsStats = {
  today: number;
  requested: number;
  proposed: number;
  rejected: number;
};

export type ReservationWithResourceId = ReservationInterface & {
  resourceId: string;
};

export type ReservationWithAction = ReservationInterface & {
  action: 'accept' | 'assignMaterial' | 'completeAction';
};

export type TReservationsByCategory = {
  category: { resourceId: string; name: string };
  reservations: ReservationWithResourceId[];
};

export class ReservationsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getPaginatedReservations({
    pagination,
    sorting,
    filtering,
    columnFilters,
    query
  }: GetReservationsProps): Promise<TGetReservations | undefined> {
    //set paignation
    const page = pagination.pageIndex + 1;
    const limit = pagination.pageSize;

    // set sorting
    let sortBy = '';
    let sortDirection = '';

    //set columnFilters
    const columnFilterURI = buildColumnFilterURI(columnFilters);

    for (let i = 0; i < sorting.length; i++) {
      const id = sorting[i].id,
        direction = sorting[i].desc ? 'DESC' : 'ASC';
      sortBy += id;
      sortDirection += direction;

      if (i !== sorting.length - 1) {
        sortBy += ',';
        sortDirection += ',';
      }
    }

    let URI = query
      ? `reservation?limit=${limit}&page=${page}&${query}`
      : `reservation?limit=${limit}&page=${page}`;
    if (filtering) {
      const filteringWithoutDot = filtering.replace('.', ''); //we remove [.] to avoid 500 error. Needs to be reviewd if we search for emails
      URI += `&filter.or.sale.user.firstName=string.like.${filteringWithoutDot}&filter.or.sale.user.lastName=string.like.${filteringWithoutDot}&filter.or.id=string.like.${filteringWithoutDot}&filter.or.sale.saleConsumers.consumer.firstName=string.like.${filteringWithoutDot}&filter.or.sale.saleConsumers.consumer.lastName=string.like.${filteringWithoutDot}`;
    }
    if (sorting.length) {
      URI += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }
    if (columnFilters.length) {
      URI += columnFilterURI;
    }
    const response: ApiResponse<TGetReservations> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getReservations(): Promise<ReservationInterface[]> {
    const response: ApiResponse<TGetReservations> =
      await this.api.apisauce.get(`reservation`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getReservationById(
    reservationId: string
  ): Promise<ReservationInterface | undefined> {
    const response: ApiResponse<ReservationInterface> =
      await this.api.apisauce.get(`reservation/${reservationId}`);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getReservationByServiceId(URI: string) {
    const response: ApiResponse<TGetReservations> = await this.api.apisauce.get(
      `reservation?${URI}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async patchReservation(
    {
      status,
      reservationId,
      query
    }: {
      status: string;
      reservationId: string;
      query?: QueryParams;
    },
    body: {
      date: number;
    }
  ): Promise<any> {
    let URI = `reservation/${status}/${reservationId}`;
    if (query) {
      const queryParams = createQueryParams(query);
      URI += `?${queryParams}`;
    }

    const response = await this.api.apisauce.patch(URI, body);

    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchConsumerStatus({
    reservationId,
    body
  }: {
    reservationId: string;
    body: { consumer: string; status: 'Present' | 'Absent' };
  }): Promise<any> {
    const response = await this.api.apisauce.patch(
      `reservation/${reservationId}/consumer-status`,
      body
    );

    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getReservationsStats(): Promise<TReservationsStats | undefined> {
    const response: ApiResponse<TReservationsStats> =
      await this.api.apisauce.get('reservation/stats');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getReservationsToday(): Promise<ReservationInterface[]> {
    const response: ApiResponse<TGetReservations> = await this.api.apisauce.get(
      'reservation-equipment/today'
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getReservationsPendingActions(): Promise<ReservationWithAction[]> {
    const response: ApiResponse<TGetReservationsWithPendingActions> =
      await this.api.apisauce.get('reservation/pending-actions');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }

  async getReservationsByCategory(
    dateRange: TDateRange
  ): Promise<TReservationsByCategory[] | undefined> {
    const response: ApiResponse<TGetReservations> = await this.api.apisauce.get(
      `reservation?filter.startTime=number.between.${dateRange.from.getTime()},${dateRange.to.getTime()}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    //TODO: cambiar logica cuando tengamos endpoint correcto
    const reservationsByCategory: TReservationsByCategory[] = [
      {
        category: {
          resourceId: 'windsurf',
          name: 'Wind Surf'
        },
        reservations: []
      },
      {
        category: {
          resourceId: 'paddlesurf',
          name: 'Paddle Surf'
        },
        reservations: []
      },
      {
        category: {
          resourceId: 'wingfoil',
          name: 'Wing Foil'
        },
        reservations: []
      }
    ];
    if (response.data?.results) {
      response.data.results.forEach((reservation) => {
        if (reservation.service.name.includes('Windsurf')) {
          reservationsByCategory[0].reservations.push({
            ...reservation,
            resourceId: 'windsurf'
          });
        }
        if (reservation.service.name.includes('Paddle')) {
          reservationsByCategory[1].reservations.push({
            ...reservation,
            resourceId: 'paddlesurf'
          });
        }
        if (reservation.service.name.includes('Wingfoil')) {
          reservationsByCategory[2].reservations.push({
            ...reservation,
            resourceId: 'wingfoil'
          });
        }
      });
    }
    return reservationsByCategory;
  }
  async getReservationByConsumer(consumerId: string) {
    const URI = `reservation?filter.sale.saleConsumers.consumer.id=string.in.${consumerId}`;
    const response: ApiResponse<TGetReservations> =
      await this.api.apisauce.get(URI);
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data?.results || [];
  }
}
