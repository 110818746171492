import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { TScheduleStrigified } from '@/services/organization';
import { useQuery } from '@tanstack/react-query';

function useGetServicesCheckSchedule(orgSchedule: TScheduleStrigified) {
  const organizationCode = useOrganizationName();

  return useQuery({
    queryKey: ['services-check-schedule', orgSchedule, organizationCode],
    queryFn: () => api.services.getCheckByActives(orgSchedule),
    enabled: !!orgSchedule.activeDays && !!orgSchedule.activeMonths
  });
}

export default useGetServicesCheckSchedule;
