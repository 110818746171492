import { TActiveDays } from '@/services/organization';
import { useState } from 'react';

export type Days = keyof TActiveDays;

const useActiveDays = (
  hoursRanges: TActiveDays,
  setHoursRanges: (value: TActiveDays) => void
) => {
  const [selectedDay, setSelectedDay] = useState<Days>('monday');
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);

  const handleAddTimeRange = (startTime: number, endTime: number) => {
    if (selectedDay !== null) {
      const newHourRange = [startTime, endTime];
      const newHoursRanges = {
        ...hoursRanges,
        [selectedDay]: [...hoursRanges[selectedDay], newHourRange]
      };
      setHoursRanges(newHoursRanges);
    }
  };

  const handleRemoveTimeRange = (day: Days, index: number) => {
    const newHoursRanges = {
      ...hoursRanges,
      [day]: hoursRanges[day].filter((_, i) => i !== index)
    };
    setHoursRanges(newHoursRanges);
  };

  return {
    selectedDay,
    setSelectedDay,
    isTimeModalOpen,
    setIsTimeModalOpen,
    handleAddTimeRange,
    handleRemoveTimeRange
  };
};

export default useActiveDays;
