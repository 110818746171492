import type React from 'react';
import TableTooltip from './table-tooltip/TableTooltip';

interface TooltipRendererProps {
  text: string;
  content: string;
}

const TooltipRenderer: React.FC<TooltipRendererProps> = ({ text, content }) => (
  <TableTooltip text={text} className="font-semibold" content={content} />
);

export default TooltipRenderer;
