import type { TIdentificationType } from '@/services/identification';
import type { TUsers } from '@/services/user';
import type { Row, SortingFn } from '@tanstack/react-table';
import { format } from 'date-fns';
import { download, generateCsv } from 'export-to-csv';
import { csvConfig } from './utils';

export const sortBirthdateFn: SortingFn<TUsers> = (rowA, rowB, _columnId) => {
  const statusA = rowA.original.birthdate
    ? new Date(rowA.original.birthdate).getTime()
    : 0;
  const statusB = rowB.original.birthdate
    ? new Date(rowB.original.birthdate).getTime()
    : 0;
  return statusA - statusB;
};

export const sortUserFn: SortingFn<TUsers> = (rowA, rowB, _columnId) => {
  const statusA = rowA.original?.active;
  const statusB = rowB.original?.active;
  if (statusA && !statusB) return -1;
  else if (!statusA && statusB) return 1;
  else return 0;
};

export const sortNameFn: SortingFn<TUsers> = (rowA, rowB, _columnId) => {
  const firstNameA = rowA.original?.firstName || '';
  const firstNameB = rowB.original?.firstName || '';
  return firstNameA.localeCompare(firstNameB);
};

const config = csvConfig({ filename: 'users' });

export const exportUserCSV = (
  rows: Row<TUsers>[],
  allIdentificationTypes: TIdentificationType[] | undefined
) => {
  if (!allIdentificationTypes) return;
  const rowData = rows.map((row) => {
    const data = row.original;
    const keysToDelete: (keyof TUsers)[] = [
      'createdBy',
      'updatedBy',
      'updatedAt',
      'authId',
      'username'
    ];
    keysToDelete.forEach((key) => delete data[key]);

    return {
      id: data.id ?? '',
      create_at: data.createdAt ? format(data.createdAt, 'dd/M/y H:mm') : '',
      user_type: data.user ? 'Consumer' : 'User',
      related_user: data.user
        ? `${data.user.firstName} ${data.user.lastName}`
        : '',
      emergency_full_name: data.emergencyContact?.fullName ?? '',
      emergency_phone: data.emergencyContact?.phone ?? '',
      first_name: data.firstName ?? '',
      last_name: data.lastName ?? '',
      birthdate: data.birthdate ? format(data.birthdate, 'dd/M/y') : '',
      email: data.email ?? '',
      phone: data.phone ?? '',
      identification: data.identification?.number
        ? data.identification?.number
        : '',
      identification_type: data.identification?.type
        ? allIdentificationTypes?.find(
            (value) => value.id === data.identification?.type
          )?.name
        : '',
      verified: data.verified ?? '',
      location: data.location ?? '',
      address: data.address ?? '',
      postal_code: data.postalCode ?? '',
      weight: data.weight ?? '',
      height: data.height ?? ''
    };
  });
  const csv = generateCsv(config)(rowData);
  download(config)(csv);
};

export function getDefaultValue(value: any): string | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (value) {
    return 'yes';
  }
  return 'no';
}
