import AvailabilityReference from '@/components/common/availability-reference/AvailabilityReference';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import { Checkbox } from '@/components/ui/checkbox';
import { DialogFooter } from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import usePostClouserEquipment from '@/hooks/queries/equipments-api/usePostClosureEquipment';
import blockEquipmentSchema from '@/schemas/block-equipment';
import {
  MODIFIERS_STYLES_AVAILABILITY,
  getDatesByAvailability,
  getDefaultMonth
} from '@/utils/closures';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { t } from 'i18next';
import { differenceWith, isEqual } from 'lodash';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import type { z } from 'zod';
import type { FormBlockEquipmentProps } from './formBlockEquipment.types';
import { getValuesAvailable, getValuesBlocked } from './utils';

const BlockEquipmentForm = ({
  equipmentId,
  availableDates,
  onOpenChange
}: FormBlockEquipmentProps) => {
  const { mutate, isPending } = usePostClouserEquipment(onOpenChange);
  const form = useForm<z.infer<typeof blockEquipmentSchema>>({
    resolver: zodResolver(blockEquipmentSchema),
    defaultValues: {
      date: undefined,
      time: []
    }
  });

  function onSubmit(values: z.infer<typeof blockEquipmentSchema>) {
    const originalValues = availableDates[format(values.date, 'dd-MM-yyyy')];
    const changedValues = differenceWith(values.time, originalValues, isEqual);
    if (changedValues.length) {
      const blocks = {
        [format(values.date, 'dd-MM-yyyy')]: changedValues
      };
      mutate({ blocks, equipmentId });
    } else {
      form.setError(
        'time',
        { type: 'focus', message: 'Realiza un cambio' },
        { shouldFocus: true }
      );
    }
  }

  const time = form.getValues().date;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col ">
              <FormControl className="mx-auto my-0">
                <div className="flex w-full justify-evenly">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={(e) => {
                      if (typeof e !== 'undefined') {
                        field.onChange(e);
                        form.resetField('time');
                        const allSlotsInDay =
                          availableDates[format(e, 'dd-MM-yyyy')];
                        form.setValue('time', allSlotsInDay);
                      }
                    }}
                    disabled={(date) => {
                      if (!availableDates) return true;
                      return !Object.keys(availableDates).includes(
                        format(date, 'dd-MM-yyyy')
                      );
                    }}
                    defaultMonth={getDefaultMonth(availableDates)}
                    initialFocus
                    modifiers={{
                      ...getDatesByAvailability(availableDates),
                      selectedDay: field.value
                    }}
                    modifiersStyles={MODIFIERS_STYLES_AVAILABILITY}
                  />
                  <AvailabilityReference />
                </div>
              </FormControl>
              <FormMessage />
              <p className="text-sm italic text-gray-500">
                {t('form.availabilityEquipment.selectDay')}
              </p>
            </FormItem>
          )}
        />
        {time ? (
          <FormField
            control={form.control}
            name="time"
            render={() => {
              const label = format(time, "d 'de' MMMM yyyy");
              return (
                <FormItem>
                  <FormLabel>{label}</FormLabel>
                  {availableDates ? (
                    <>
                      <FormField
                        key={`${time}-allDay`}
                        control={form.control}
                        name="time"
                        render={({ field }) => {
                          const item = 'Todo el dia';
                          return (
                            <FormItem
                              key={`${time}-allDay`}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={
                                    !field.value?.find(
                                      (value) => value.status === 'available'
                                    )
                                  }
                                  onCheckedChange={(checked) => {
                                    if (checked) {
                                      field.onChange(
                                        getValuesBlocked(field.value)
                                      );
                                    } else {
                                      field.onChange(
                                        getValuesAvailable(field.value)
                                      );
                                    }
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="text-sm font-normal">
                                {item}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                      {availableDates[format(time, 'dd-MM-yyyy')].map(
                        (item, index) => {
                          const assignedLabel =
                            item.status === 'assigned'
                              ? t(
                                  'form.availabilityEquipment.assignedEquipemnet'
                                )
                              : '';
                          return (
                            <FormField
                              key={`${time}-${item.slot}`}
                              control={form.control}
                              name="time"
                              render={({ field }) => {
                                return (
                                  <FormItem
                                    key={`${time}-${item.slot}`}
                                    className="flex flex-row items-start space-x-3 space-y-0"
                                  >
                                    <FormControl>
                                      <Checkbox
                                        disabled={item.status === 'assigned'}
                                        checked={
                                          field.value[index].status ===
                                            'blocked' ||
                                          field.value[index].status ===
                                            'assigned'
                                        }
                                        onCheckedChange={(checked) => {
                                          const newValue = field.value;
                                          if (
                                            checked &&
                                            item.status !== 'assigned'
                                          ) {
                                            newValue[index].status = 'blocked';
                                          } else if (
                                            item.status !== 'assigned'
                                          ) {
                                            newValue[index].status =
                                              'available';
                                          }
                                          return field.onChange(newValue);
                                        }}
                                      />
                                    </FormControl>
                                    <FormLabel className="text-sm font-normal">
                                      {item.slot}{' '}
                                      <span className="ml-4 text-xs italic">
                                        {assignedLabel}
                                      </span>
                                    </FormLabel>
                                  </FormItem>
                                );
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  ) : null}
                  <FormMessage />
                  <p className="text-sm italic text-gray-500">
                    {t('form.availabilityEquipment.blockUnblockMessage')}
                  </p>
                </FormItem>
              );
            }}
          />
        ) : null}

        <DialogFooter>
          <Button type="submit" disabled={isPending}>
            {isPending ? (
              <div className="flex justify-center">
                <Loader className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              t('common.save')
            )}
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default BlockEquipmentForm;
