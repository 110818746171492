import { BlockServiceForm } from '@/components/forms/form-block-services/FormBlockServices';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import useGetServiceActiveDates from '@/hooks/queries/services-api/useGetServiceActiveDates';
import usePostClousureCheck from '@/hooks/queries/services-api/usePostClosureCheck';
import type { TCheckModalState } from '@/lib/utils';
import { format } from 'date-fns';
import { t } from 'i18next';
import { differenceWith, isEqual } from 'lodash';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';
import type { BlockServiceModalT } from '../block-service-reducer/block-services-modals-types';

export function SchoolBlockServiceModal({
  state,
  dispatch
}: Readonly<BlockServiceModalT>) {
  const {
    mutate: postClousureCheck,
    isPending: isPendingClosureCheck,
    data: closureCheck
  } = usePostClousureCheck((checkModal) => {
    dispatch({
      type: 'SET_CHECK_MODAL',
      payload: checkModal as TCheckModalState
    });
  });

  const { data: availableDates, isFetching } = useGetServiceActiveDates();
  const isLoading = !!availableDates && !isFetching;

  useEffect(() => {
    dispatch({ type: 'SET_CLOSURE_CHECK', payload: closureCheck });
  }, [closureCheck, dispatch]);

  return (
    <Dialog
      open={state.checkModal.state ? false : state.state}
      onOpenChange={() => dispatch({ type: 'CLOSE_MODAL' })}
    >
      <DialogContent className="sm:max-w-[425px] pr-0 ">
        <DialogHeader>
          <DialogTitle>
            {t('settings.schoolAvailability')}:
            <span className="font-normal"> </span>
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh] pr-4">
          {isLoading ? (
            <BlockServiceForm
              availableDates={availableDates}
              isPending={isPendingClosureCheck}
              onSubmit={(values, form) => {
                const originalValues =
                  availableDates[format(values.date, 'dd-MM-yyyy')];
                const changedValues = differenceWith(
                  values.time,
                  originalValues,
                  isEqual
                );

                if (changedValues.length) {
                  const closures = {
                    [format(values.date, 'dd-MM-yyyy')]: changedValues
                  };
                  dispatch({ type: 'SET_CLOSURES', payload: closures });
                  postClousureCheck({ closures });
                } else {
                  form.setError(
                    'time',
                    { type: 'focus', message: 'Realiza un cambio' },
                    { shouldFocus: true }
                  );
                }
              }}
            />
          ) : (
            <div className="flex justify-center">
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
