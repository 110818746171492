export function getValuesBlocked(
  values: Array<{ status: string; [key: string]: any }>
) {
  return values.map((value) => ({
    ...value,
    status: value.status === 'assigned' ? value.status : 'blocked'
  }));
}

export function getValuesAvailable(
  values: Array<{ status: string; [key: string]: any }>
) {
  return values.map((value) => ({
    ...value,
    status: value.status === 'assigned' ? value.status : 'available'
  }));
}

export function hasAvailable(values: Array<{ status: string }>) {
  return values?.some((value) => value.status === 'available');
}
