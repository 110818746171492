import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { subYears } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { z } from 'zod';
import { identificationValidation } from './validations';

export const allowPaymentSchema = z.enum(['admin', 'all']);
export const visibilitySchema = z.enum(['admin', 'all']);
export const canUpdateReservationsSchema = z.enum(['admin', 'all']);

export const rangeTimeSchema = z
  .string({ required_error: 'schemas.common.dateRequired' })
  .regex(
    /^([01]\d|2[0-3]):([0-5]\d)-([01]\d|2[0-3]):([0-5]\d)$/,
    'schemas.common.timeRageValid'
  );

export const phoneSchema = z
  .string({ required_error: 'schemas.common.phoneRequired' })
  .transform((arg, ctx) => {
    if (!arg) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'schemas.common.enterPhone'
      });
      return '';
    }

    const phone = parsePhoneNumberFromString(arg, {
      extract: false
    });

    // valid
    if (phone && phone.isValid()) {
      return phone.number;
    }

    // invalid
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'schemas.common.phoneFormat'
    });
  });

export const heightSchema = z.coerce
  .number({ required_error: 'schemas.common.heightRequired' })
  .min(minHeight, {
    message: `schemas.common.heightMin|{"minHeight": ${minHeight}}`
  })
  .max(maxHeight, {
    message: `schemas.common.heightMax|{"maxHeight": ${maxHeight}}`
  });

export const weightSchema = z.coerce
  .number({ required_error: 'schemas.common.weightRequired' })
  .min(minWeight, {
    message: `schemas.common.weightMin|{"minWeight": ${minWeight}}`
  })
  .max(maxWeight, {
    message: `schemas.common.weightMax|{"maxWeight": ${maxWeight}}`
  });

export const birthdateSchema = ({
  minAge,
  maxAge
}: {
  minAge?: number;
  maxAge?: number;
}) => {
  const min = minAge ? subYears(new Date(), minAge) : new Date();

  const schema = z
    .date({
      required_error: 'schemas.common.birthdateRequired'
    })
    .max(min, {
      message: minAge
        ? `schemas.common.birdthdateMin|{"minAge": ${minAge}}`
        : 'schemas.common.dateValid'
    });
  if (maxAge) {
    const max = maxAge ? subYears(new Date(), maxAge) : new Date();
    return schema.min(max, {
      message: maxAge
        ? `schemas.common.birdthdateMax|{"maxAge": ${maxAge}}`
        : 'schemas.common.dateValid'
    });
  }
  return schema;
};

export const emailSchema = z
  .string({
    required_error: 'schemas.common.emailRequired'
  })
  .email({
    message: 'schemas.common.emailValid'
  });

export const identificationSchema = z
  .object({
    number: z.string({
      required_error: 'schemas.common.identificationRequired'
    }),
    type: z.string({ required_error: 'schemas.common.identificationRequired' })
  })
  .superRefine((obj, ctx) => {
    identificationValidation(obj, ctx);
    return false;
  });

export const firstNameSchema = z
  .string({ required_error: 'schemas.common.firstNameRequired' })
  .min(2, {
    message: 'schemas.common.firstNameMin'
  });

export const lastNameSchema = z
  .string({ required_error: 'schemas.common.lastNameRequired' })
  .min(2, {
    message: 'schemas.common.lastNameMin'
  });
