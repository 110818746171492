import { maxHeight, maxWeight, minHeight, minWeight } from '@/utils/utils';
import { isBefore, subYears } from 'date-fns';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { z } from 'zod';

export const phoneValidation = (
  value: string,
  path: [string],
  ctx: z.RefinementCtx
) => {
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'schemas.common.enterPhone',
      path
    });
    return '';
  }

  const phone = parsePhoneNumberFromString(value, {
    extract: false
  });

  // valid
  if (phone && phone.isValid()) {
    return phone.number;
  }

  // invalid
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: '+Código de país seguido por el número',
    path
  });
};

export const birthdateValidation = (
  minAge: number,
  value: Date | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'schemas.common.birthdateRequired',
      path
    });
    return z.NEVER;
  }
  if (isBefore(subYears(new Date(), minAge), value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `schemas.common.birdthdateMin|{"minAge": ${minAge}}`,
      path
    });
    return z.NEVER;
  }

  // valid
  return value;
};

export const heightValidation = (
  value: number | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'schemas.common.heightRequired',
      path
    });
    return z.NEVER;
  }
  if (value < minHeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `schemas.common.heightMin|{"minHeight": ${minHeight}}`,
      path
    });
    return z.NEVER;
  }
  if (value > maxHeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `schemas.common.heightMax|{"maxHeight": ${maxHeight}}`,
      path
    });
    return z.NEVER;
  }

  // valid
  return value;
};

export const weightValidation = (
  value: number | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // invalid
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'schemas.common.weightRequired',
      path
    });
    return z.NEVER;
  }
  if (value < minWeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `schemas.common.weightMin|{"minWeight": ${minWeight}}`,
      path
    });
    return z.NEVER;
  }
  if (value > maxWeight) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `schemas.common.weightMax|{"maxWeight": ${maxWeight}}`,
      path
    });
    return z.NEVER;
  }
  // valid
  return value;
};

export function identificationValidation(
  value: { type: string; number: string } | undefined,
  ctx: z.RefinementCtx,
  path?: [string]
) {
  if (!value) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ['type'],
      message: 'schemas.common.identificationRequired'
    });
    return z.NEVER;
  }

  if (!value.type) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: path ? [...path, 'type'] : ['type'],
      message: 'schemas.common.identificationTypeRequired'
    });
  }

  if (!value.number) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: path ? [...path, 'number'] : ['number'],
      message: 'schemas.common.identificationRequired'
    });
    return z.NEVER;
  }

  return value;
}

export const nameValidation = (
  value: string | undefined,
  path: [string],
  ctx: z.RefinementCtx
) => {
  // valid
  if (value && value.length >= 2) {
    return value;
  }

  // invalid
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: 'Debe tener como mínimo 2 caracteres.',
    path
  });
  return z.NEVER;
};
