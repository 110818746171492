export function getFieldValueQuestion(field: any) {
  let defaultValue: string | undefined;
  if (field.value.value === undefined) {
    defaultValue = undefined;
  } else if (field.value.value) {
    defaultValue = 'yes';
  } else {
    defaultValue = 'no';
  }

  return defaultValue;
}
