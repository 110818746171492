import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import ServiceDivisionForm from '@/components/forms/form-service-division/ServiceDivisionForm';
import { TForm } from '@/components/forms/form-service-division/ServiceDivisionForm.types';
import { Skeleton } from '@/components/ui/skeleton';
import usePostServiceDivision from '@/hooks/queries/service-division-api/usePostServiceDivision';
import useGetServiceById from '@/hooks/queries/services-api/useGetServiceById';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const CreateServiceDivision = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const { data: service, isError, isLoading } = useGetServiceById(serviceId);
  const { mutate, isPending } = usePostServiceDivision();

  if (isLoading) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[50vh]" />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!service) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const onSubmit = (values: TForm) => {
    mutate({ ...values, service: service.id });
  };

  return (
    <div className="mb-4">
      <GoBack />
      <div className="p-5 space-y-6 rounded-md bg-accent">
        <p className="font-bold text-md text-primary">
          {t('serviceDetail.createServiceDivision.title')} - {service.name}
        </p>
        <ServiceDivisionForm onSubmit={onSubmit} isPending={isPending} />
      </div>
    </div>
  );
};

export default CreateServiceDivision;
