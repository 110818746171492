import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { X } from 'lucide-react';
import React from 'react';

interface AnnouncementCardProps {
  id: string;
  title: string;
  description?: string;
  action: (id: string) => void;
}

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
  id,
  title,
  description,
  action
}) => {
  return (
    <Card className="relative">
      <Button
        variant="ghost"
        size="icon"
        className="absolute right-2 top-2"
        onClick={() => action(id)}
        aria-label="Cerrar anuncio"
      >
        <X className="w-4 h-4" />
      </Button>

      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>

      {description && (
        <CardContent>
          <p className="text-muted-foreground">{description}</p>
        </CardContent>
      )}
    </Card>
  );
};
