import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { Loader } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { DeleteAnnouncementsModalT } from './delete-announcements-modal.types';
import useDeleteAnnouncement from '@/hooks/queries/announcements-api/useDeleteAnnouncement';

const DeleteServicesCategoryModal = ({
  open,
  onOpenChange,
  announcementId
}: DeleteAnnouncementsModalT) => {
  const { t } = useTranslation();
  const { mutate, isPending } = useDeleteAnnouncement();

  const handleDelete = () => {
    mutate(announcementId);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t('modals.alert.title')}</DialogTitle>
          <DialogDescription>
            {t('modals.alert.deleteAnnouncements')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t('modals.alert.close')}</Button>
          </DialogClose>
          <Button
            variant="destructive"
            onClick={handleDelete}
            disabled={isPending}
          >
            {isPending ? (
              <Loader className="w-4 h-4 animate-spin" />
            ) : (
              t('modals.alert.delete')
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteServicesCategoryModal;
