import { Badge } from '@/components/ui/badge';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetReservationsToday from '@/hooks/queries/reservations-api/useGetReservationsToday';
import useFormattedDate from '@/hooks/useFormattedDate';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { sortTimeStampStartTimeFn } from '@/lib/utils';
import { getTimeZone } from '@/utils/dates';
import { getReservationsTexts } from '@/utils/reservations';
import { dateInDataRange } from '@/utils/utils';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { t } from 'i18next';
import { ArrowRight, Check, X } from 'lucide-react';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import type { ReservationInterface } from '../tables.types';
import TableToolBar from './TableToolBar';

export function ReservationsTodayTable<TData, TValue>() {
  const { formattedDate } = useFormattedDate();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'startTime', desc: false }]
  });

  const { data: organization } = useGetOrganization();
  const { data: reservations, isLoading, isError } = useGetReservationsToday();

  const emptyData = (reservations?.length ?? 0) === 0;

  const columns = useMemo<ColumnDef<ReservationInterface>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'consumers[0].firstName',
        accessorKey: 'consumers[0].firstName',
        accessorFn: (originalRow) =>
          `${originalRow.consumers[0].firstName} ${originalRow.consumers[0].lastName}`,
        header: t('tables.common.name')
      },
      {
        id: 'service.name',
        accessorKey: 'service.name',
        header: t('tables.common.activity'),
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/services-sold/${reservation.sale.id}`
              }}
              className="underline"
            >
              {reservation.service.name}
            </Link>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'startTime',
        accessorKey: 'startTime',
        sortingFn: sortTimeStampStartTimeFn,
        cell: ({ row }) => {
          const startTime = row.original.startTime;
          const tz = organization?.tz ?? '';

          return tz && startTime
            ? formattedDate({
                date: getTimeZone(Number(startTime), tz),
                dateFormat: 'time'
              })
            : '-';
        },
        header: t('tables.common.time'),
        filterFn: (row, id, value) =>
          dateInDataRange(new Date(row.getValue(id)), value)
      },
      {
        id: 'consumers[0].equipment',
        accessorKey: 'consumers[0].equipment',
        accessorFn: (originalRow) =>
          originalRow.consumers[0].equipment[0]?.name
            ? originalRow.consumers[0].equipment.map((eq) => eq.code).join(', ')
            : '-',
        header: t('tables.reservations.material')
      },
      {
        id: 'isPaid',
        accessorKey: 'isPaid',
        header: t('tables.reservations.payment'),
        cell: ({ row }) => {
          const isPaid = row.original.isPaid;
          return isPaid ? <Check color="green" /> : <X color="red" />;
        }
      },
      {
        id: t('tables.reservations.insurance'),
        accessorKey: 'consumers[0].hasFilledData',
        header: t('tables.reservations.insurance'),
        cell: ({ row }) => {
          const hasFilledData = row.original.consumers[0].hasFilledData;
          return hasFilledData ? <Check color="green" /> : <X color="red" />;
        }
      },
      {
        id: 'reservationStatus.name',
        accessorKey: 'reservationStatus.name',
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Badge
              variant={reservation.reservationStatus.name}
              className="justify-center h-8 justify-self-end"
            >
              {getReservationsTexts(reservation.reservationStatus.name)}
            </Badge>
          );
        },
        header: t('common.status.status'),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/reservations/${reservation.id}`
              }}
            >
              <ArrowRight />
            </Link>
          );
        }
      }
    ],
    [formattedDate, organization?.tz]
  );

  const table = useReactTable({
    data: reservations ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    //sort config
    enableMultiSort: false, // disable multi-sorting for the entire table
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    //filter config
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onColumnFiltersChange: setColumnFilters,
    enableRowSelection: true,
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      sorting,
      globalFilter: filtering,
      columnFilters,
      pagination
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <TableToolBar
        table={table}
        emptyData={emptyData}
        filtering={filtering}
        setFiltering={setFiltering}
      />
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
}
