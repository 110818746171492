import Activity from '@/pages/protected/Activity';
import Announcements from '@/pages/protected/Announcements';
import Attendance from '@/pages/protected/Attendance';
import CalendarV1Page from '@/pages/protected/CalendarV1';
import CreateAnnouncements from '@/pages/protected/CreateAnnouncements';
import CreateInventory from '@/pages/protected/CreateInventory';
import CreateInventoryCategory from '@/pages/protected/CreateInventoryCategory';
import CreateService from '@/pages/protected/CreateService';
import CreateServiceDivision from '@/pages/protected/CreateServiceDivision';
import CreateServicesCategory from '@/pages/protected/CreateServicesCategory';
import DashboardPage from '@/pages/protected/Dashboard';
import EditAnnouncements from '@/pages/protected/EditAnnouncements';
import EditInventory from '@/pages/protected/EditInventory';
import EditInventoryCategory from '@/pages/protected/EditInventoryCategory';
import EditService from '@/pages/protected/EditService';
import EditServiceDivision from '@/pages/protected/EditServiceDivision';
import EditServicesCategory from '@/pages/protected/EditServicesCategory';
import InventoryPage from '@/pages/protected/Inventory';
import InventoryCategoriesPage from '@/pages/protected/InventoryCategories';
import NewBookingPage from '@/pages/protected/NewBooking';
import PaymentsPage from '@/pages/protected/Payments';
import QuickActionsPage from '@/pages/protected/QuickActions';
import ReservationDetail from '@/pages/protected/ReservationDetail';
import ReservationsPage from '@/pages/protected/Reservations';
import ServiceDetailPage from '@/pages/protected/ServiceDetail';
import ServicesPage from '@/pages/protected/Services';
import ServicesCategoriesPage from '@/pages/protected/ServicesCategories';
import ServicesSold from '@/pages/protected/ServicesSold';
import ServicesSoldDetail from '@/pages/protected/ServicesSoldDetail';
import SettingsPage from '@/pages/protected/Settings';
import StudentView from '@/pages/protected/StudentView';
import StudentViewEdit from '@/pages/protected/StudentViewEdit';
import UserDetail from '@/pages/protected/UserDetail';
import Users from '@/pages/protected/Users';
import UserSalesDetail from '@/pages/protected/UserSalesDetail';
import type { RouteObject } from 'react-router-dom';

export const PROTECTED_ROUTES: RouteObject[] = [
  { path: '/dashboard', element: <DashboardPage /> },
  { path: '/users', element: <Users /> },
  { path: '/users/edit/:id', element: <UserDetail /> },
  { path: '/users/:id', element: <UserSalesDetail /> },
  { path: '/calendar', element: <CalendarV1Page /> },
  { path: '/services', element: <ServicesPage /> },
  { path: '/activity', element: <Activity /> },
  { path: '/activity/students/:id', element: <StudentView /> },
  { path: '/activity/students/:id/edit', element: <StudentViewEdit /> },
  { path: '/attendance', element: <Attendance /> },
  { path: '/services/categories', element: <ServicesCategoriesPage /> },
  { path: '/services/categories/create', element: <CreateServicesCategory /> },
  { path: '/services/categories/edit/:id', element: <EditServicesCategory /> },
  { path: '/services/create', element: <CreateService /> },
  { path: '/services/:id', element: <ServiceDetailPage /> },
  { path: '/services/:id/edit', element: <EditService /> },
  { path: '/services/:id/create-division', element: <CreateServiceDivision /> },
  {
    path: '/services/:id/edit-division/:divisionId',
    element: <EditServiceDivision />
  },
  { path: '/inventory', element: <InventoryPage /> },
  { path: '/inventory/create', element: <CreateInventory /> },
  { path: '/inventory/edit/:id', element: <EditInventory /> },
  { path: '/inventory/categories', element: <InventoryCategoriesPage /> },
  {
    path: '/inventory/categories/create',
    element: <CreateInventoryCategory />
  },
  {
    path: '/inventory/categories/edit/:id',
    element: <EditInventoryCategory />
  },
  { path: '/payments', element: <PaymentsPage /> },
  { path: '/services-sold/', element: <ServicesSold /> },
  { path: '/services-sold/:id', element: <ServicesSoldDetail /> },
  { path: '/reservations', element: <ReservationsPage /> },
  { path: '/reservations/:id', element: <ReservationDetail /> },
  { path: '/settings', element: <SettingsPage /> },
  { path: '/new-booking', element: <NewBookingPage /> },
  { path: '/quick-actions', element: <QuickActionsPage /> },
  { path: '/announcements', element: <Announcements /> },
  { path: '/announcements/create', element: <CreateAnnouncements /> },
  { path: '/announcements/edit/:id', element: <EditAnnouncements /> }
];
