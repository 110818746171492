import type { ApiResponse } from 'apisauce';
import type { Api } from './api';

export interface TOrganization {
  id: string;
  name: string;
  code: string;
  description: string;
  email: string;
  maxCancellationTime: number;
  overdueCancellationPenalty: number;
  activeDays: string;
  activeMonths: string;
  additionalData?: string;
  tz: string;
  phone: string;
  address: string;
  location: string;
  postalCode: string;
  currency: string;
  icon: string;
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: boolean;
}

type localeTypes = 'en' | 'es';

interface TLanguage {
  createdBy: string;
  updatedBy: string;
  createdAt: number;
  updatedAt: number;
  active: boolean;
  id: string;
  name: string;
  englishName: string;
  code: localeTypes;
}

export interface TFaq {
  id: string;
  language: TLanguage;
  question: string;
  answer: string;
  order: number;
}

export interface TOrganizationFAQ extends TOrganization {
  faq: TFaq[];
}

export interface TGetOganization {
  total: number;
  limit: number;
  offset: number;
  page: number;
  hasNextPage: boolean;
  results: TOrganizationFAQ[];
}

export type TActiveDays = Record<
  | 'sunday'
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday',
  number[][]
>;

export type TActiveMonths = Record<
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december',
  number[][]
>;

export interface TSchedule {
  activeMonths: TActiveMonths;
  activeDays: TActiveDays;
}

export interface TScheduleStrigified {
  activeDays: string;
  activeMonths: string;
}

export class OrganizationApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getOrganization(
    organizationName: string
  ): Promise<TOrganizationFAQ | undefined> {
    const response: ApiResponse<TOrganizationFAQ> = await this.api.apisauce.get(
      `organization/${organizationName}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async getCheckByActives({
    activeMonths,
    activeDays
  }: TScheduleStrigified): Promise<TOrganization | null | undefined> {
    const response: ApiResponse<TOrganization | null> =
      await this.api.apisauce.get(
        `organization/check-schedule?activeMonths=${activeMonths}&activeDays=${activeDays}`
      );
    if (!response.ok) {
      throw response.originalError;
    }
    // TODO: Mejorar respuesta de backend o transformar a una estructura unificada
    // Ejemplo: { status: 'schedule-conflict', data: { activeMonths, activeDays } } (TOrganization)
    // Ejemplo: { status: 'schedule-ok', data: false } (undefined o boolean)
    return response.data;
  }

  async patchSchedule(
    payload: Partial<TSchedule>
  ): Promise<TSchedule | undefined> {
    const response: ApiResponse<TSchedule> = await this.api.apisauce.patch(
      'organization/schedule',
      payload
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
