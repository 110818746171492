import GoBack from '@/components/common/go-back/GoBack';
import FormAnnouncements, {
  AnnouncementsValues
} from '@/components/forms/form-announcements/FormAnnouncements';
import usePostAnnouncements from '@/hooks/queries/announcements-api/usePostAnnouncements';
import useGetOrganizationFromCache from '@/hooks/queries/organization-api/useGetOrganizationFromCache';
import { getTimestampUTC } from '@/utils/dates';
import { addDays } from 'date-fns';

const CreateAnnouncements = () => {
  const { mutate, isPending } = usePostAnnouncements();
  const organization = useGetOrganizationFromCache();

  const onSubmit = (data: AnnouncementsValues) => {
    const parsedData = {
      ...data,
      startTime: getTimestampUTC({
        date: data.startTime,
        time: '00:00:00',
        tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
      }),
      endTime: getTimestampUTC({
        date: data.endTime,
        time: '23:59:59',
        tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
      })
    };
    mutate({ data: parsedData });
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormAnnouncements
          onSubmit={onSubmit}
          isPending={isPending}
          defaultValues={{
            title: '',
            description: '',
            startTime: new Date(new Date().setHours(0, 0, 0, 0)),
            endTime: addDays(new Date(new Date().setHours(0, 0, 0, 0)), 1),
            audience: ''
          }}
        />
      </div>
    </>
  );
};

export default CreateAnnouncements;
