import { ApiResponse } from 'apisauce';
import { Api } from './api';
import { TOrganization } from './organization';

export type TAnnouncement = {
  id: string;
  title: string;
  description?: string;
  createdAt: number;
  createdBy?: number;
  updatedAt: number;
  updatedBy?: number;
  active: boolean;
  startTime: number;
  endTime?: number;
  organization: TOrganization;
  audience: string;
};

type TGetAnnouncements = TAnnouncement[];

type TPostAnnouncement = {
  title: string;
  description?: string;
  startTime: number;
  endTime?: number;
  audience: string;
};

export class AnnouncementsApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getAnnouncements(): Promise<TAnnouncement[]> {
    const response: ApiResponse<any> =
      await this.api.apisauce.get('announcements');
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data || [];
  }

  async getAnnouncementById(announcementId: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.get(
      `announcements/${announcementId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async postAnnouncement(body: any): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.post(
      `announcements`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }

  async patchAnnouncement(
    body: Partial<any>,
    announcementId: string
  ): Promise<any | undefined> {
    const response: ApiResponse<any> = await this.api.apisauce.patch(
      `announcements/${announcementId}`,
      body
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
  async deleteAnnouncement(announcementId: string): Promise<any> {
    const response: ApiResponse<any> = await this.api.apisauce.delete(
      `announcements/${announcementId}`
    );
    if (!response.ok) {
      throw response.originalError;
    }
    return response.data;
  }
}
