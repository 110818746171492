import { Months } from '@/hooks/useActiveMonths';
import { X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export const MonthRanges = ({
  month,
  ranges,
  handleRemoveDateRange
}: {
  month: string;
  ranges: number[][];
  handleRemoveDateRange: (month: Months, index: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-2 p-3 bg-white border rounded-md">
      <div className="flex gap-4">
        <p className="font-medium min-w-24">{t(`months.${month}`)}</p>
        <div className="flex flex-wrap gap-2">
          {ranges.length === 0 ? (
            <p className="text-sm">{t('schedule.noActivityActiveMonths')}</p>
          ) : (
            ranges
              .sort(([startA], [startB]) => startA - startB)
              .map(([start, end], index) => (
                <div
                  key={`${month}-${index}`}
                  className="flex items-center gap-2 px-2 py-1 rounded-3xl bg-primary text-primary-foreground hover:bg-primary/90"
                >
                  <p className="text-sm">
                    {start} - {end}
                  </p>
                  <span
                    className="cursor-pointer"
                    onClick={() =>
                      handleRemoveDateRange(month as Months, index)
                    }
                  >
                    <X className="w-4 h-4" />
                  </span>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
};
