import { RootStore } from '@/stores/root-store.types';
import { StoreApi } from 'zustand';
import { TActiveTab } from './dashboard.types';

export const createDashboardSlice = (
  set: StoreApi<RootStore>['setState'],
  get: StoreApi<RootStore>['getState'],
  ...rest: StoreApi<RootStore>[]
) => ({
  activeTab: 'pending',
  setActiveTab: (tab: TActiveTab) =>
    set((state) => ({ activeTab: (state.activeTab = tab) }))
});
