import GoBack from '@/components/common/go-back/GoBack';
import StudentAttendanceDays from '@/components/common/student-attendance/StudentAttendanceDays';
import AditionalDataSection from '@/components/section/student-view/AditionalDataSection';
import AuthorizedPickupSection from '@/components/section/student-view/AuthorizedPickupSection';
import CardHeaderSection from '@/components/section/student-view/CardHeaderSection';
import HeaderSection from '@/components/section/student-view/HeaderSection';
import MedicalHistorySection from '@/components/section/student-view/MedicalHistorySection';
import { PersonalDataSection } from '@/components/section/student-view/PersonalDataSection';
import { Card } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import useGetConsumer from '@/hooks/queries/consumer-api/useGetConsumerById';
import { getGroup } from '@/lib/utils';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const StudentView: React.FC = () => {
  const { t } = useTranslation();
  const { id: consumerId } = useParams();
  const { data: consumer, isPending } = useGetConsumer(consumerId);
  let additionalData = consumer?.additionalData;
  if (typeof consumer?.additionalData === 'string')
    additionalData = JSON.parse(consumer?.additionalData ?? '');

  const dateConsumer =
    consumer?.birthdate !== undefined
      ? new Date(consumer.birthdate)
      : new Date();
  const day = String(dateConsumer.getDate()).padStart(2, '0');
  const month = String(dateConsumer.getMonth() + 1).padStart(2, '0');
  const year = dateConsumer.getFullYear();
  const birthdateConsumer = `${day}/${month}/${year}`;
  const group = getGroup(additionalData);

  return (
    <>
      <GoBack />
      <div className="">
        {/* Header */}
        <HeaderSection isPending={isPending} consumer={consumer} t={t} />

        <Card className="w-full p-8 mb-6 border-none bg-accent">
          {/* Personal Data Section */}
          <CardHeaderSection t={t} />
          {isPending ? (
            <>
              {[...Array(12)].map((item, index) => (
                <div key={`${index}-${item}`} className="flex gap-12 mb-6">
                  <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
                  <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
                </div>
              ))}
            </>
          ) : (
            <>
              <div className="grid gap-8 mb-8 md:grid-cols-2">
                <PersonalDataSection
                  additionalData={additionalData}
                  consumer={consumer}
                  group={group}
                  birthdateConsumer={birthdateConsumer}
                  t={t}
                />

                <AditionalDataSection t={t} additionalData={additionalData} />
              </div>

              {/* Authorized Pickup Section */}
              <AuthorizedPickupSection t={t} additionalData={additionalData} />

              {/* Medical History Section */}
              <MedicalHistorySection t={t} additionalData={additionalData} />

              <div className="mb-8">
                <h2 className="mb-4 text-xl font-semibold">
                  {t('activity.table.observation')}
                </h2>
                <p>{additionalData?.observations}</p>
              </div>

              {/* Payments Section */}
              {/* <div className="mb-8">
          <h2 className="mb-4 text-xl font-semibold text-red-500">
            {t('activity.student.pendingPayments')}
          </h2>
          <div className="space-y-2">
            <p>{t('activity.student.totalPaid')}: $10</p>
            <p>{t('activity.student.pendingAmount')} $12312</p>
          </div>
        </div> */}
            </>
          )}

          {/* Attendance Section */}
          <div>
            <h2 className="mb-4 text-xl font-semibold">
              {t('activity.student.attendance')}
            </h2>
            <StudentAttendanceDays
              consumerId={consumerId}
              disabledDays={true}
            />
          </div>
        </Card>
      </div>
    </>
  );
};

export default StudentView;
