import { differenceInYears } from 'date-fns';
import { Skeleton } from '@/components/ui/skeleton';
import { TConsumer } from '@/services/consumer';

export function PersonalDataSection({
  t,
  consumer,
  additionalData,
  birthdateConsumer,
  group
}: {
  t: any;
  consumer: TConsumer | undefined;
  additionalData: any;
  birthdateConsumer: string;
  group: string;
}) {
  return (
    <div className="space-y-4">
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.lastName')}:
        </p>
        <p>{consumer?.lastName}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.firstName')}:
        </p>
        <p>{consumer?.firstName}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {consumer?.identification.type}:
        </p>
        <p>{consumer?.identification.number}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.birthDate')}:
        </p>
        <p>{birthdateConsumer}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.address')}:
        </p>
        <p>{consumer?.location}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.age')}:
        </p>
        <p>
          {consumer
            ? differenceInYears(new Date(), new Date(consumer?.birthdate))
            : ''}
        </p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.group')}:
        </p>
        <p>{group}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.shirtSize')}:
        </p>
        <p>{additionalData?.size?.toUpperCase()}</p>
      </div>
    </div>
  );
}

export function PersonalDataSkeleton() {
  return (
    <>
      {[...Array(12)].map((_, index) => (
        <div key={index} className="flex gap-12 mb-6">
          <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
          <Skeleton className="w-full h-6 bg-slate-300 justify-self-center" />
        </div>
      ))}
    </>
  );
}
