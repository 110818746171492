import usePostClouserServices from '@/hooks/queries/services-api/usePostClosureServices';
import type { TBlockServiceSchedule } from '@/services/services';
import { type Dispatch, useEffect } from 'react';
import { BlockServiceModal } from '../block-service-modal/BlockServiceModal';
import CheckReservationsModal from '../check-resevations-modal/checkReservationsModal';
import { SchoolBlockServiceModal } from '../school-block-service-modal/SchoolBlockServiceModal';
import StatusDetailModal from '../status-detail-modal/StatusDetailModal';
import type { ModalActionsTypes, ModalState } from './block-modal-reducer';

export default function BlockServiceReducerContainer({
  dispatch,
  state,
  isSchool = false
}: Readonly<{
  state: ModalState;
  dispatch: Dispatch<ModalActionsTypes>;
  isSchool?: boolean;
}>) {
  const { mutate: postClouserServices } = usePostClouserServices();
  const serviceId = String(state.serviceId);
  const onSubmit = (data: {
    closures: TBlockServiceSchedule;
    serviceId?: string;
  }) => {
    !isSchool
      ? postClouserServices({
          ...data,
          serviceId
        })
      : postClouserServices({
          ...data
        });
  };

  useEffect(() => {
    if (state.closureCheck !== undefined) {
      dispatch({ type: 'SET_MODAL_STEP', payload: 1 });
    }
  }, [dispatch, state.closureCheck]);

  return (
    <>
      {!isSchool
        ? state.modalsStep === 0 &&
          state.serviceId && (
            <BlockServiceModal state={state} dispatch={dispatch} />
          )
        : state.modalsStep === 0 && (
            <SchoolBlockServiceModal state={state} dispatch={dispatch} />
          )}

      {state.modalsStep === 1 && state.closureCheck !== undefined && (
        <CheckReservationsModal
          dispatch={dispatch}
          state={state}
          onSubmit={onSubmit}
        />
      )}

      {state.modalsStep === 2 && (
        <StatusDetailModal
          dispatch={dispatch}
          state={state}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}
