import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import type { TForm } from '@/components/forms/form-participant-data-vp/FormParticipantDataVp.types';
import FormStudents from '@/components/forms/form-students/FormStudents';
import { Skeleton } from '@/components/ui/skeleton';
import useGetConsumer from '@/hooks/queries/consumer-api/useGetConsumerById';
import useUpdateUser from '@/hooks/queries/users-api/useUptadeUser';
import { getChangedValues } from '@/utils/forms';
import { ChevronRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const StudentViewEdit = () => {
  const { t } = useTranslation();
  const { id: consumerId } = useParams();
  const { data: consumer, isPending, isError } = useGetConsumer(consumerId);
  const redirectTo = '/activity';
  const { mutate, isPending: isSending } = useUpdateUser(redirectTo);

  if (isPending) {
    return (
      <>
        <GoBack />

        <div className="grid grid-cols-2">
          <Skeleton className="flex flex-col w-full h-full gap-10 px-4 py-8 ">
            {[...Array(5)].map((item, index) => (
              <div className="flex flex-col gap-2" key={`${index}-${item}`}>
                <Skeleton className="w-20 h-4 bg-slate-200" />
                <Skeleton className="w-full h-10 bg-slate-200" />
              </div>
            ))}
          </Skeleton>
          <Skeleton className="flex flex-col w-full h-full gap-10 px-4 py-8 ">
            {[...Array(5)].map((item, index) => (
              <div className="flex flex-col gap-2" key={`${index}-${item}`}>
                <Skeleton className="w-20 h-4 bg-slate-200" />
                <Skeleton className="w-full h-10 bg-slate-200" />
              </div>
            ))}
          </Skeleton>
        </div>
      </>
    );
  }

  if (!consumer) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  if (isError || !consumerId) {
    return (
      <>
        <GoBack />
        <ErrorMessage />
      </>
    );
  }

  return (
    <>
      <GoBack />
      <div className="">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center gap-2 text-muted-foreground">
            <span>{t('activity.student.students')}</span>
            <ChevronRight className="w-4 h-4" />
            {isPending ? (
              <>...</>
            ) : (
              <span>{`${consumer?.firstName} ${consumer?.lastName}`}</span>
            )}
          </div>
          <FormStudents
            consumer={consumer}
            isSending={isSending}
            onSubmit={(data: TForm) => {
              const changedValues = {
                ...data,
                ...data.user,
                firstName: data.user.firstName,
                lastName: data.user.lastName,
                birthdate: data.user.birthdate.toISOString(),
                identification: data.user.identification,
                phone: data.parents[0].phone,
                location: data.user.location,
                email: data.parents[0].email
              };

              const { user, ...restChangedValues } = changedValues;

              const consumerAdditionalData = {
                ...consumer,
                ...consumer.additionalData
              };

              delete consumerAdditionalData.additionalData;
              const changes = getChangedValues(
                consumerAdditionalData,
                restChangedValues
              );
              mutate({
                id: consumerId,
                body: changes
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default StudentViewEdit;
