import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import {
  keepPreviousData,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import type {
  ColumnFiltersState,
  PaginationState,
  SortingState
} from '@tanstack/react-table';
import { useEffect } from 'react';

export type GetSalesProps = {
  pagination: PaginationState;
  filtering: string;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
};

type GetQueryOptions = GetSalesProps & {
  organizationName: string;
};

const getQueryOptions = ({
  pagination,
  filtering,
  sorting,
  columnFilters,
  organizationName
}: GetQueryOptions) => {
  return {
    queryKey: [
      'sales',
      pagination,
      { search: filtering },
      sorting,
      columnFilters,
      organizationName
    ],
    queryFn: () =>
      api.sales.getPaginatedSales({
        pagination,
        filtering,
        sorting,
        columnFilters
      })
  };
};

const useGetPaginatedSales = (props: GetSalesProps) => {
  const queryClient = useQueryClient();
  const organizationName = useOrganizationName();

  //prefetch next page
  useEffect(() => {
    queryClient.prefetchQuery(
      getQueryOptions({
        ...props,
        pagination: {
          ...props.pagination,
          pageIndex: props.pagination.pageIndex + 1
        },
        organizationName
      })
    );
  }, [queryClient, props, organizationName]);

  return useQuery({
    ...getQueryOptions({ ...props, organizationName }),
    placeholderData: keepPreviousData,
    refetchInterval: 5 * 60 * 1000 // 5 minutes
  });
};

export default useGetPaginatedSales;
