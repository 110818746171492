import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DatePickerCalendar from '../../date-picker-calendar/DatePickerCalendar';
import ActiveMonthsSchema, {
  ActiveMonthsValues
} from '@/schemas/active-months';
import { TActiveMonths } from '@/services/organization';

interface ActiveMonthsModalProps {
  dateRanges: TActiveMonths;
  isOpen: boolean;
  onClose: () => void;
  onAdd: (startDate: Date, endDate: Date) => void;
}

export function ActiveMonthsModal({
  dateRanges,
  isOpen,
  onClose,
  onAdd
}: Readonly<ActiveMonthsModalProps>) {
  const form = useForm<ActiveMonthsValues>({
    resolver: zodResolver(ActiveMonthsSchema(dateRanges))
  });
  const { t } = useTranslation();

  const onSubmit = (data: ActiveMonthsValues) => {
    onAdd(data.startDate, data.endDate);
    form.reset();
    onClose();
  };

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent>
        <X
          className="absolute w-5 h-5 cursor-pointer right-4 top-4"
          onClick={() => {
            form.reset();
            onClose();
          }}
        />
        <AlertDialogHeader className="mb-2">
          <AlertDialogTitle>{t('activeMonths.addRangeDates')}</AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col w-full gap-4"
          >
            <div className="flex gap-4 rounded-md">
              <FormField
                control={form.control}
                name="startDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col w-full">
                    <FormLabel>{t('activeMonths.startDate')}</FormLabel>
                    <DatePickerCalendar field={field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col w-full">
                    <FormLabel>{t('activeMonths.endDate')}</FormLabel>
                    <DatePickerCalendar field={field} />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="scheduleConflict"
              render={() => <FormMessage />}
            />
            <div className="flex justify-end">
              <Button type="submit">{t('activeMonths.add')}</Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
