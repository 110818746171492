import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const inventorySchema = () => {
  return z.object({
    name: z
      .string({
        required_error: 'schemas.inventory.nameRequired'
      })
      .min(4, 'schemas.inventory.nameMin')
      .max(100, 'schemas.inventory.nameMax'),
    code: z
      .string({
        required_error: 'schemas.inventory.codeRequired'
      })
      .min(1, 'schemas.inventory.codeMin'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.medium,
        `schemas.inventory.descriptionMax|{"maxLength": ${DESCRIPTION_MAX_LENGTH.medium}}`
      )
      .optional(),
    equipmentType: z.string({
      required_error: 'schemas.inventory.equipmentTypeRequired'
    })
  });
};

export default inventorySchema;
