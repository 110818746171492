import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetPaginatedPayments from '@/hooks/queries/payments-api/useGetPaginatedPayments';
import usePatchPayments from '@/hooks/queries/payments-api/usePatchPayments';
import useFormattedDate from '@/hooks/useFormattedDate';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import { sortTimeStampPaidAtFn, sortUpdatedAtFn } from '@/lib/utils';
import { getPaymentStatusTexts } from '@/utils/reservations';
import { currencyFormat, dateInDataRange } from '@/utils/utils';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { EditIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import AddPaymentModal from '../../modals/add-payment-modal/AddPaymentModal';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableToolBar from './TableToolBar';
import type { PaymentTable } from './payments-table.types';

const PaymentsTable = () => {
  const { formattedDate } = useFormattedDate();
  const { t } = useTranslation();
  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'paidAt', desc: true }]
  });

  const { data: organization } = useGetOrganization();
  const { mutate, isPending } = usePatchPayments();

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [paymentSelected, setPaymentSelected] = useState<PaymentTable | null>(
    null
  );
  const {
    data: dataPayments,
    isLoading: isLoadingPayments,
    isError,
    isFetching
  } = useGetPaginatedPayments({
    pagination,
    filtering,
    sorting,
    columnFilters
  });
  const payments = dataPayments?.results;
  const emptyData = !payments?.length;

  const columns = useMemo<ColumnDef<PaymentTable>[]>(
    () => [
      {
        id: 'reference',
        accessorKey: 'reference',
        header: t('modals.addPayment.orderReference'),
        accessorFn: (originalRow) =>
          originalRow.reference && originalRow.paymentType.name === 'TPV'
            ? `#${originalRow.reference}`
            : '-'
      },
      {
        id: 'sale.user.firstName',
        accessorKey: 'fullName',
        accessorFn: (originalRow) =>
          `${originalRow.sale.user.firstName}  ${originalRow.sale.user.lastName}`,
        header: t('tables.common.name')
      },
      {
        id: 'sale.saleItems.name',
        accessorKey: 'sale.saleItems.name',
        header: t('tables.common.activity'),
        cell: ({ row }) => {
          const payment = row.original;
          return (
            <Link
              to={{
                pathname: `/services-sold/${payment.sale.id}`
              }}
              className="underline"
            >
              {payment.sale.service.name}
            </Link>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'sale.amountPaid',
        accessorKey: 'amountPaid',
        header: t('modals.addPayment.amountPaid'),
        accessorFn: (originalRow) =>
          organization
            ? currencyFormat(originalRow.amount, organization.currency)
            : undefined,
        enableSorting: false
      },
      {
        id: 'sale.amountPending',
        accessorKey: 'amountPending',
        header: t('modals.addPayment.amountPending'),
        accessorFn: (originalRow) =>
          organization
            ? currencyFormat(
                originalRow.sale.amountPending,
                organization.currency
              )
            : undefined
      },
      {
        id: 'sale.totalAmount',
        accessorKey: 'total',
        header: t('modals.addPayment.total'),
        accessorFn: (originalRow) =>
          organization
            ? currencyFormat(
                originalRow.sale.service.price,
                organization.currency
              )
            : undefined
      },
      {
        id: 'paymentStatus.name',
        accessorKey: 'paymentStatus.name',
        header: t('modals.addPayment.paymentStatus'),
        cell: ({ row }) => {
          const paymentStatus = row.original.paymentStatus.name;
          return (
            <Badge
              variant={paymentStatus}
              className="justify-center h-8 justify-self-end"
            >
              {getPaymentStatusTexts(paymentStatus)}
            </Badge>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'paymentType.name',
        accessorKey: 'paymentType.name',
        header: t('modals.addPayment.paymentType')
      },
      {
        id: t('modals.addPayment.comments'),
        accessorKey: 'observations',
        header: t('modals.addPayment.comments'),
        enableSorting: false
      },
      {
        id: 'paidAt',
        accessorKey: 'paidAt',
        sortingFn: sortTimeStampPaidAtFn,
        header: t('tables.common.paidAt'),
        filterFn: (row, id, value) => {
          return dateInDataRange(row.getValue(id), value);
        },
        cell: ({ row }) => {
          const paidAt = row.original.paidAt;
          return formattedDate({ date: Number(paidAt) });
        }
      },
      {
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        sortingFn: sortUpdatedAtFn,
        accessorFn: (originalRow) =>
          formattedDate({ date: originalRow.updatedAt }),
        header: t('tables.common.updatedAt')
      },
      {
        accessorKey: 'id',
        header: 'ID'
      },
      {
        id: 'actions',
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => {
          const isWeb = row.original.paymentType.name === 'TPV';
          if (isWeb) return null;
          return (
            <Button
              variant={'ghost'}
              onClick={() => {
                setPaymentSelected(row.original);
                setOpenPaymentModal(true);
              }}
            >
              <EditIcon />
            </Button>
          );
        }
      }
    ],
    [formattedDate, organization, t]
  );

  const table = useReactTable({
    data: payments ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: dataPayments?.total,
    pageCount: Math.ceil(
      (dataPayments?.total ?? 0) / (dataPayments?.limit ?? 1)
    ),
    manualPagination: true, //turn off client-side pagination
    //sort config
    enableMultiSort: false, // disable multi-sorting for the entire table
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    //filter config
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    state: {
      sorting,
      globalFilter: filtering,
      pagination,
      columnFilters
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <TableToolBar
        table={table}
        emptyData={emptyData}
        filtering={filtering}
        setFiltering={setFiltering}
        isFetching={isFetching}
      />

      <TanStackBasicTableTableComponent
        table={table}
        isLoading={isLoadingPayments}
      />

      {openPaymentModal && paymentSelected && organization ? (
        <AddPaymentModal
          open={openPaymentModal}
          organization={organization}
          onOpenChange={setOpenPaymentModal}
          sale={paymentSelected.sale}
          paymentSelected={paymentSelected}
          onSave={(formValues) => {
            mutate({ paymentId: paymentSelected?.id, data: formValues });
            setOpenPaymentModal(false);
            setPaymentSelected(null);
          }}
          loading={isPending}
        />
      ) : null}
    </>
  );
};

export default PaymentsTable;
