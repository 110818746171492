import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import FormAnnouncements, {
  AnnouncementsValues
} from '@/components/forms/form-announcements/FormAnnouncements';
import useGetAnnouncementById from '@/hooks/queries/announcements-api/useGetAnnouncementById';
import usePatchAnnouncements from '@/hooks/queries/announcements-api/usePatchAnnouncements';
import useGetOrganizationFromCache from '@/hooks/queries/organization-api/useGetOrganizationFromCache';
import { getTimestampUTC } from '@/utils/dates';
import { getChangedValues } from '@/utils/forms';
import { Loader } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'sonner';

const EditAnnouncements = () => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const data = new URLSearchParams(search).get('announcement');

  const parsedAnnouncement = typeof data === 'string' ? JSON.parse(data) : null;
  const announcementId = parsedAnnouncement.id;
  const defaultValues = {
    title: parsedAnnouncement.title,
    description: parsedAnnouncement.description,
    startTime: new Date(parsedAnnouncement.startTime),
    endTime: new Date(parsedAnnouncement.endTime),
    audience: parsedAnnouncement.audience
  };

  const { mutate, isPending } = usePatchAnnouncements();
  const organization = useGetOrganizationFromCache();
  // const {
  //   data: announcement,
  //   isLoading: isLoadingAnnouncement,
  //   isError: isErrorAnnouncement
  // } = useGetAnnouncementById(announcementId ?? '');

  // if (isLoadingAnnouncement) {
  //   return (
  //     <div className="flex items-center justify-center">
  //       <Loader className="animate-spin" />
  //     </div>
  //   );
  // }

  if (!announcementId) {
    // if (!announcementId || isErrorAnnouncement) {
    return <ErrorMessage />;
  }

  const onSubmit = (data: AnnouncementsValues) => {
    type partialType = Omit<AnnouncementsValues, 'startTime' | 'endTime'> & {
      startTime: number;
      endTime: number;
    };
    const onlyChangedData = getChangedValues<Partial<partialType>>(
      {
        ...defaultValues,
        startTime: parsedAnnouncement.startTime,
        endTime: parsedAnnouncement.endTime
      },
      {
        ...data,
        startTime: getTimestampUTC({
          date: data.startTime,
          time: '00:00:00',
          tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
        }),
        endTime: getTimestampUTC({
          date: data.endTime,
          time: '23:59:59',
          tz: organization?.tz ?? 'America/Argentina/Buenos_Aires'
        })
      }
    );
    if (Object.keys(onlyChangedData).length > 0) {
      mutate({
        payload: onlyChangedData,
        announcementId
      });
    } else toast.error(t('toast.noChanges'));
  };

  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        <FormAnnouncements
          onSubmit={onSubmit}
          isPending={isPending}
          isEdit={true}
          defaultValues={defaultValues}
          announcementId={announcementId}
        />
      </div>
    </>
  );
};

export default EditAnnouncements;
