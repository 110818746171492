import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Days } from '@/hooks/useActiveDays';
import ActiveHoursSchema, { ActiveHoursValues } from '@/schemas/active-days';
import { TActiveDays } from '@/services/organization';
import { zodResolver } from '@hookform/resolvers/zod';
import { X } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ActiveHoursModalProps {
  selectedDay: Days;
  hoursRanges: TActiveDays;
  isOpen: boolean;
  onClose: () => void;
  onAdd: (startTime: number, endTime: number) => void;
}

export function ActiveHoursModal({
  hoursRanges,
  selectedDay,
  isOpen,
  onClose,
  onAdd
}: Readonly<ActiveHoursModalProps>) {
  const { t } = useTranslation();

  const form = useForm<ActiveHoursValues>({
    defaultValues: { startTime: '', endTime: '' },
    resolver: zodResolver(ActiveHoursSchema(hoursRanges[selectedDay]))
  });

  const onSubmit = (data: ActiveHoursValues) => {
    onAdd(Number(data.startTime), Number(data.endTime));
    form.reset();
    onClose();
  };
  const timeOptions = Array.from({ length: 24 }, (_, hour) => {
    return hour.toString();
  });

  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(openState) => {
        if (!openState) form.reset();
        onClose();
      }}
    >
      <AlertDialogContent>
        <X
          className="absolute w-5 h-5 right-4 top-4"
          onClick={() => {
            onClose();
            form.reset();
          }}
        />
        <AlertDialogHeader className="mb-2 ">
          <AlertDialogTitle>{t('activeHours.addRangeHours')}</AlertDialogTitle>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col w-full gap-4"
          >
            <div className="flex gap-4 rounded-md">
              <FormField
                control={form.control}
                name="startTime"
                render={({ field }) => (
                  <FormItem className="flex flex-col w-full">
                    <FormLabel>{t('common.startTime')}</FormLabel>
                    <Select onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t('activeHours.selectTime')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {timeOptions.map((hour: string) => (
                          <SelectItem key={`${hour}_start`} value={hour}>
                            {hour.toString().padStart(2, '0')}:00
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endTime"
                render={({ field }) => (
                  <FormItem className="flex flex-col w-full">
                    <FormLabel>{t('common.endTime')}</FormLabel>
                    <Select onValueChange={field.onChange}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t('activeHours.selectTime')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {timeOptions.map((hour: string) => (
                          <SelectItem key={`${hour}_end`} value={hour}>
                            {hour.toString().padStart(2, '0')}:00
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="scheduleConflict"
              render={() => <FormMessage />}
            />
            <div className="flex justify-end">
              <Button type="submit">{t('activeHours.add')}</Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
