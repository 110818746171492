import { ReservationsPendingTable } from '@/components/common/tables/reservations-pending-table/ReservationsPendingTable';
import { ReservationsTodayTable } from '@/components/common/tables/reservations-today-table/ReservationsTodayTable';
import QuickActionsStats from '@/components/section/quick-actions/QuickActionsStats';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useGetOrganizationFromCache from '@/hooks/queries/organization-api/useGetOrganizationFromCache';
import useGetUserFromCache from '@/hooks/queries/users-api/useGetUserFromCache';
import { VEPCodes } from '@/lib/utils';
import type { TUser } from '@/services/user';
import { useRootStore } from '@/stores/root-store';
import type { TActiveTab } from '@/stores/slices/dashboard/dashboard.types';
import type { Role } from '@/utils/roles';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const QuickActionsPage = () => {
  const { t } = useTranslation();
  const activeTab = useRootStore((store) => store.activeTab);
  const setActiveTab = useRootStore((store) => store.setActiveTab);
  const organization = useGetOrganizationFromCache();
  const userData = useGetUserFromCache();
  const navigate = useNavigate();
  const codeOrganization = organization?.code;

  const navigateBasedOnRole = (userRole: Role) => {
    if (userRole === 'Monitor') {
      navigate(`/attendance`);
    } else {
      navigate(`/services-sold`);
    }
  };

  const handleNavigation = (userData: TUser) => {
    const userRole = userData?.userType?.name ?? 'Customer';
    if (VEPCodes.includes(codeOrganization ?? '')) {
      navigateBasedOnRole(userRole);
    }
  };

  useEffect(() => {
    if (userData) {
      handleNavigation(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  return (
    <div className="w-full">
      <QuickActionsStats />
      <Tabs
        onValueChange={(value) => setActiveTab(value as TActiveTab)}
        defaultValue={activeTab}
      >
        <TabsList>
          <TabsTrigger value="today"> {t('common.today')}</TabsTrigger>
          <TabsTrigger value="pending">
            {t('common.status.pending')}
          </TabsTrigger>
        </TabsList>
        <TabsContent value="today">
          <ReservationsTodayTable />
        </TabsContent>
        <TabsContent value="pending">
          <ReservationsPendingTable />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default QuickActionsPage;
