import { z } from 'zod';

const announcementsSchema = () => {
  return z
    .object({
      title: z
        .string({
          required_error: 'schemas.announcements.titleRequired'
        })
        .min(10, 'schemas.announcements.titleMin')
        .max(100, 'schemas.announcements.titleMax'),
      description: z
        .string()
        .max(500, 'schemas.announcements.descriptionMax')
        .optional(),
      startTime: z.date({ required_error: 'schemas.common.dateRequired' }),
      endTime: z.date({ required_error: 'schemas.common.dateRequired' }),
      audience: z.string().min(1, 'schemas.announcements.audienceRequired')
    })
    .refine((data) => data.startTime <= data.endTime, {
      message: 'schemas.announcements.errorStartTimeGreater',
      path: ['startTime']
    });
};

export default announcementsSchema;
