import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { format, set } from 'date-fns';
import { CalendarIcon, X } from 'lucide-react';
import type { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
const RangeDatePicker = ({
  date,
  onSelect,
  disabled
}: {
  date: DateRange | undefined;
  onSelect: (value: DateRange | undefined) => void;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const isSmallScreen = window.matchMedia('(max-width: 639px)').matches;

  const getFormattedDateRange = (
    date: { from?: Date; to?: Date } | null | undefined
  ): JSX.Element | string => {
    if (!date?.from) {
      return <span>{t('common.filterByDate')}</span>;
    }

    if (date.to) {
      return (
        <>
          {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
        </>
      );
    }

    return format(date.from, 'LLL dd, y');
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          variant={'outline'}
          className={`${!date ? 'text-muted-foreground' : ' '} w-full flex items-center justify-start`}
          disabled={disabled}
        >
          <CalendarIcon className="w-4 h-4 mr-2" />
          {getFormattedDateRange(date)}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 z-[100]"
        align="start"
        side="bottom"
        sideOffset={55}
      >
        <PopoverClose
          aria-label="Close"
          className="flex justify-end w-full px-3 pt-2"
        >
          <X />
        </PopoverClose>

        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={(value) => {
            const formattedDate = {
              from: value?.from
                ? set(new Date(value?.from), {
                    hours: 0,
                    minutes: 0,
                    seconds: 0
                  })
                : undefined,
              to: value?.to
                ? set(new Date(value?.to), {
                    hours: 23,
                    minutes: 59,
                    seconds: 59
                  })
                : undefined
            };
            onSelect(formattedDate);
          }}
          numberOfMonths={isSmallScreen ? 1 : 2}
        />
      </PopoverContent>
    </Popover>
  );
};

export default RangeDatePicker;
