import type React from 'react';
import { options } from '@/components/forms/form-announcements/FormAnnouncements';

interface AudienceCellProps {
  audience: string;
}

const AudienceCell: React.FC<AudienceCellProps> = ({ audience }) => {
  const option = options.find((opt) => opt.value === audience);
  return <span>{option?.label ?? '-'}</span>;
};

export default AudienceCell;
