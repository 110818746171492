import { format, isSameDay, isWithinInterval } from 'date-fns';
import { mkConfig } from 'export-to-csv';
import type { DateRange } from 'react-day-picker';

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export const currencyFormat = (price: number, currency: string) => {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: currency
  }).format(price);
};

export function checkAndPush(obj: any) {
  const valuesArray = [];
  for (const key in obj) {
    if (Object.hasOwn(obj, key) && obj[key] !== '') {
      valuesArray.push(obj[key]);
    }
  }
  return valuesArray;
}

export const minAges = {
  paddle: 7,
  windsurf: 9,
  wingfoil: 10,
  userMinAge: 18
};
export const dateInDataRange = (rowDate: Date, dateRange: DateRange) => {
  const fromDate = dateRange?.from;
  const toDate = dateRange?.to;
  if (fromDate && toDate) {
    return isWithinInterval(rowDate, { start: fromDate, end: toDate });
  }
  if (fromDate || toDate) {
    return fromDate
      ? isSameDay(rowDate, fromDate)
      : toDate
        ? isSameDay(rowDate, toDate)
        : false;
  }

  return false;
};

export const parseDateToDMY = (isoDate: string) => {
  return format(new Date(isoDate), 'dd/MM/yyyy');
};

export const minHeight = 110;
export const maxHeight = 250;

export const minWeight = 5;
export const maxWeight = 500;

export type QueryParams = {
  [key: string]: string | number | boolean | null | undefined;
};
export const createQueryParams = (params: QueryParams) => {
  const queryParams = new URLSearchParams();

  // Recorrer el objeto params y agregar las claves con valores
  for (const [key, value] of Object.entries(params)) {
    if (value !== undefined && value !== null) {
      queryParams.append(key, value.toString());
    }
  }

  // Verificar si queryParams contiene datos
  const queryString = queryParams.toString();
  return queryString;
};

export const fullName = ({
  firstName,
  lastName
}: {
  firstName: string;
  lastName: string;
}) => `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;

export interface PaginatedResponse<T> {
  total: number;
  offset: number;
  limit: number;
  page: number;
  hasNextPage: boolean;
  results: T[];
}

export const VISIBILITY = {
  admin: 'admin',
  all: 'all'
};

export const ALLOW_PAYMENT = {
  admin: 'admin',
  all: 'all'
};

export const CAN_UPDATE_RESERVATIONS = {
  admin: 'admin',
  all: 'all'
};

export type VisibilityType = keyof typeof VISIBILITY;

export const getFeatureFlagValue = (flag: string) => {
  const featureFlags = process.env.FEATURE_FLAG
    ? JSON.parse(process.env.FEATURE_FLAG)
    : {};
  return featureFlags[flag];
};

export const csvConfig = ({ filename }: { filename: string }) =>
  mkConfig({
    fieldSeparator: ';',
    filename, // export file name (without .csv)
    decimalSeparator: ',',
    useKeysAsHeaders: true
  });

export enum DESCRIPTION_MAX_LENGTH {
  short = 255,
  medium = 511,
  long = 1023
}
