import { ChevronRight } from 'lucide-react';

export default function HeaderSection({
  isPending,
  consumer,
  t
}: {
  isPending: boolean;
  consumer: any;
  t: any;
}) {
  return (
    <div className="mb-6">
      <div className="flex items-center gap-2 text-muted-foreground">
        <span>{t('activity.student.students')}</span>
        <ChevronRight className="w-4 h-4" />
        {isPending ? (
          <>...</>
        ) : (
          <span>{`${consumer?.firstName} ${consumer?.lastName}`}</span>
        )}
      </div>
    </div>
  );
}
