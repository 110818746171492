import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useDateRange from '@/hooks/useDateRange';
import { TServices } from '@/services/services';
import { Table } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { Loader, XIcon } from 'lucide-react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RangeDatePicker from '../../range-date-picker/RangeDatePicker';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import { TableFacetedFilter } from '../../table-faceted-filter/TableFacetedFilter';
import TableFilters from '../../table-filters/TableFilters';
import TableSearchInput from '../common/table-search-input/TableSearchInput';

type TableToolBarProps<TData> = Readonly<{
  table: Table<TData>;
  filtering: string;
  setFiltering: (value: string) => void;
  isFetching: boolean;
}>;

export default function TableToolBar<TData>({
  table,
  filtering,
  setFiltering,
  isFetching
}: TableToolBarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const { t } = useTranslation();
  const { isPending, data: services } =
    useGetOrganizationServices<TServices[]>();

  const [search, setSearch] = useState(filtering || '');

  const { date, resetDateRange, handleOnSelectDate } = useDateRange({
    column: table.getColumn('createdAt')
  });

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const statusesOptions = [
    {
      value: 'paymentPending',
      label: t('tables.servicesSold.paymentPending'),
      icon: () => (
        <Badge variant={'Pending'} className="w-4 h-4 mx-1 rounded-full" />
      )
    },
    {
      value: 'paymentComplete',
      label: t('tables.servicesSold.paymentComplete'),
      icon: () => (
        <Badge variant={'Accepted'} className="w-4 h-4 mx-1 rounded-full" />
      )
    },
    {
      value: 'canceled',
      label: t('tables.servicesSold.canceled'),
      icon: () => (
        <Badge variant={'Rejected'} className="w-4 h-4 mx-1 rounded-full" />
      )
    }
  ];

  return (
    <div className="grid grid-cols-[auto,auto] grid-rows-2 items-center sm:grid-cols-[auto,1fr,auto] sm:grid-rows-1 gap-4 mb-4 ">
      <div className="order-1">
        <TableFilters filterActive={isFiltered}>
          <div className="flex flex-col items-start w-full gap-2">
            {table.getColumn('service.id') && (
              <TableFacetedFilter
                column={table.getColumn('service.id')}
                title={t('tables.common.activity')}
                options={
                  services?.length
                    ? services?.map((service) => {
                        return {
                          label: service.name,
                          value: service.id
                        };
                      })
                    : []
                }
                disabled={isPending || !services?.length}
              />
            )}
            {table.getColumn('status') && (
              <TableFacetedFilter
                column={table.getColumn('status')}
                title={t('tables.servicesSold.statuses')}
                options={statusesOptions}
                disabled={isPending}
              />
            )}

            {table.getColumn('createdAt') && (
              <div className="w-full">
                <RangeDatePicker
                  date={date}
                  onSelect={(value) => handleOnSelectDate(value)}
                />
              </div>
            )}
            {isFiltered && (
              <Button
                variant={'secondary'}
                onClick={() => {
                  table.resetColumnFilters();
                  resetDateRange();
                }}
                className="w-full"
              >
                {t('common.clearFilters')}
                <XIcon className="w-4 h-4 ml-2" />
              </Button>
            )}
          </div>
        </TableFilters>
      </div>
      <div className="order-2 sm:order-3 sm:w-auto ">
        <TableColumnsToggle table={table} />
      </div>
      <div className="order-3 col-span-2 sm:order-2 sm:col-span-1 sm:justify-self-end">
        <TableSearchInput value={search ?? ''} onChange={onSearch} />
        {search && isFetching && (
          <Loader className="text-gray-600 animate-spin" />
        )}
      </div>
    </div>
  );
}
