import { Button } from '@/components/ui/button';
import { Clock, X } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveHoursModal } from '../modals/active-hours-modal/ActiveHoursModal';
import { TActiveDays } from '@/services/organization';
import useActiveDays from '@/hooks/useActiveDays';
import { DayRanges } from './DayRanges';

interface Props {
  hoursRanges: TActiveDays;
  setHoursRanges: (value: TActiveDays) => void;
}

const ActiveDays = ({ hoursRanges, setHoursRanges }: Props) => {
  const { t } = useTranslation();
  const {
    isTimeModalOpen,
    selectedDay,
    setSelectedDay,
    setIsTimeModalOpen,
    handleAddTimeRange,
    handleRemoveTimeRange
  } = useActiveDays(hoursRanges, setHoursRanges);

  return (
    <div>
      <div className="flex items-center gap-2 mb-4">
        <Clock className="w-5 h-5" />
        <h2 className="font-bold">{t('activeHours.title')}</h2>
      </div>
      <div className="space-y-2">
        <DayRanges
          hoursRanges={hoursRanges}
          setSelectedDay={setSelectedDay}
          setIsTimeModalOpen={setIsTimeModalOpen}
          handleRemoveTimeRange={handleRemoveTimeRange}
        />
      </div>
      <ActiveHoursModal
        selectedDay={selectedDay}
        hoursRanges={hoursRanges}
        isOpen={isTimeModalOpen}
        onClose={() => setIsTimeModalOpen(false)}
        onAdd={handleAddTimeRange}
      />
    </div>
  );
};

export default ActiveDays;
