export default function AditionalDataSection({
  t,
  additionalData
}: {
  t: any;
  additionalData: any;
}) {
  return (
    <div className="space-y-4">
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.contacts')}:
        </p>
        {additionalData?.parents?.map(
          (parent: {
            idetification: { number: string };
            firstName: string;
            lastName: string;
            connection: string;
            identification: { number: string };
            phone: string;
            email: string;
          }) => (
            <div key={parent.identification?.number} className="mt-2">
              <p>
                {`${t('activity.student.contactName')}: ${parent.firstName} ${parent.lastName}`}
              </p>
              <p>{`${t('activity.student.contactRelation')}: ${parent.connection}`}</p>
              <p>{`${t('activity.student.contactDNI')}: ${parent.identification.number}`}</p>
              <p>{`${t('activity.student.contactPhone')}: ${parent.phone}`}</p>
              <p>{`${t('activity.student.contactEmail')}: ${parent.email}`}</p>
            </div>
          )
        )}
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.medicalCoverage')}:
        </p>
        <p>{additionalData?.insurance}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.waterSkillLevel')}:
        </p>
        <p>{additionalData?.experience}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.vacationPeriod')}:
        </p>
        <p>{additionalData?.holidayDate}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('isEmployeePartner')}:
        </p>
        <p>{additionalData?.isEmployeePartner}</p>
      </div>
      <div>
        <p className="text-sm text-muted-foreground">
          {t('activity.student.clubMember')}:
        </p>
        <p>
          <p>{`${additionalData?.isPartner ? t('form.yes') : t('form.no')}`}</p>
        </p>
      </div>
    </div>
  );
}
