import { ReservationCard } from '@/components/common/cards/reservation-card/ReservationCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import { Skeleton } from '@/components/ui/skeleton';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetReservation from '@/hooks/queries/reservations-api/useGetReservation';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { getTimeZone } from '@/utils/dates';
import { format, formatDistanceStrict } from 'date-fns';

import { useParams } from 'react-router-dom';

const ReservationDetail = () => {
  const params = useParams();
  const id = params.id ? params.id : '';
  const currentLocale = useCurrentLanguage();

  const {
    data: reservation,
    isLoading,
    isFetching,
    isError
  } = useGetReservation(id);

  const { data: organization } = useGetOrganization();

  if (isLoading) {
    return <Skeleton className="w-full h-4/5" />;
  }
  if (!reservation) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }
  if (isError) {
    return (
      <>
        <GoBack />
        <ErrorMessage />
      </>
    );
  }
  //TODO: dates in details are different to table
  const startTime =
    reservation?.startTime &&
    organization?.tz &&
    getTimeZone(Number(reservation.startTime), organization?.tz);
  const endTime =
    reservation?.endTime &&
    organization?.tz &&
    getTimeZone(Number(reservation.endTime), organization?.tz);
  const day = startTime
    ? format(startTime, "dd 'de' MMMMMMM 'del' yyyy", { locale: currentLocale })
    : undefined;
  const time = startTime ? format(startTime, 'H:mm') : undefined;
  const duration =
    startTime && endTime ? formatDistanceStrict(startTime, endTime) : undefined;
  return (
    <>
      <GoBack />
      <div className="flex justify-center w-full">
        {
          <ReservationCard
            reservation={{
              id: reservation?.id,
              title: `${reservation?.user.firstName} ${reservation?.user.lastName} - ${reservation?.service.name}`,
              status: reservation?.reservationStatus?.name,
              day,
              time,
              endTime: reservation.endTime || undefined,
              startTime: reservation.startTime || undefined,
              duration,
              consumers: reservation.consumers.map((consumer) => {
                return {
                  id: consumer.id,
                  name: `${consumer.firstName} ${consumer.lastName}`,
                  weight: consumer.weight,
                  height: consumer.height,
                  equipment: consumer.equipment,
                  status: consumer.status
                };
              }),
              enabled: reservation.enabled,
              serviceSoldId: reservation.sale.id
            }}
            service={{
              id: reservation?.service?.id
            }}
            isFetching={isFetching}
          />
        }
      </div>
    </>
  );
};

export default ReservationDetail;
