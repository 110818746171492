import usePatchReservation from './queries/reservations-api/usePatchReservation';

type QueryParams = {
  markAllPresent?: boolean;
  markAllAbsent?: boolean;
};

const useReservationActions = ({
  disabledRefetch = false
}: {
  disabledRefetch?: boolean;
} = {}) => {
  const { mutate, isPending } = usePatchReservation({ disabledRefetch });

  const handleAbsent = (reservationId: string, query?: QueryParams) => {
    mutate({ status: 'absent', reservationId: reservationId, query });
  };
  const handleCompleted = (reservationId: string, query?: QueryParams) => {
    mutate({ status: 'complete', reservationId: reservationId, query });
  };
  const handleAccept = (reservationId: string) => {
    mutate({ status: 'accept', reservationId: reservationId });
  };
  const handleReject = (reservationId: string) => {
    mutate({ status: 'reject', reservationId: reservationId });
  };
  const handleClose = (reservationId: string) => {
    mutate({ status: 'cancel', reservationId: reservationId });
  };
  const handlePropose = (date: number, reservationId: string) => {
    mutate({
      status: 'propose',
      reservationId: reservationId,
      data: { date }
    });
  };
  return {
    handleAbsent,
    handleCompleted,
    handleAccept,
    handleReject,
    handleClose,
    handlePropose,
    isPending
  };
};

export default useReservationActions;
