import { Button } from '@/components/ui/button';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetPaginatedServices from '@/hooks/queries/services-api/useGetPaginatedServices';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import type { TServices } from '@/services/services';
import { currencyFormat, getFeatureFlagValue } from '@/utils/utils';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getPaginationRowModel,
  useReactTable
} from '@tanstack/react-table';
import { ArrowRight, Check, X } from 'lucide-react';
import type React from 'react';
import { useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import {
  initalBlockState,
  modalBlockReducer
} from '../../modals/block-service-reducer/block-modal-reducer';
import BlockServiceReducerContainer from '../../modals/block-service-reducer/BlockServiceReducer';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableToolBar from './TableToolBar';

const ServicesTable: React.FC = () => {
  const { t } = useTranslation();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'sport.name', desc: false }]
  });

  const {
    data: dataServices,
    isLoading,
    isError,
    isFetching
  } = useGetPaginatedServices({
    pagination,
    filtering,
    sorting,
    columnFilters
  });

  const { data: organization } = useGetOrganization();

  const [state, dispatch] = useReducer(modalBlockReducer, initalBlockState);
  const services = dataServices?.results;
  const emptyData = !services?.length;

  const featureFlagCrudValue = getFeatureFlagValue('crud');

  const columns = useMemo<ColumnDef<TServices>[]>(
    () => [
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'name',
        accessorKey: 'name',
        header: t('services.name')
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: t('services.description'),
        cell: ({ row }) => {
          const description = row?.original?.description;
          return (
            <TableTooltip
              text={description}
              className="w-[12rem] line-clamp-3"
            />
          );
        }
      },
      {
        id: 'sport.name',
        accessorKey: 'sport.name',
        header: t('form.category')
      },
      {
        id: 'price',
        accessorKey: 'price',
        accessorFn: (rowOriginal) =>
          organization
            ? currencyFormat(rowOriginal.price, organization.currency)
            : undefined,
        header: t('services.price')
      },
      {
        id: 'reservationPct',
        accessorKey: 'reservationPct',
        header: t('services.reservePercentage'),
        cell: ({ row }) => {
          const reservationPct = row.original.reservationPct;
          return <p>{reservationPct}%</p>;
        }
      },
      {
        id: 'minConsumers',
        accessorKey: 'minConsumers',
        accessorFn: (rowOriginal) =>
          rowOriginal.minConsumers ?? t('services.noDefined'),
        meta: { headerText: t('services.minConsumers') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.minConsumers')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.minConsumersDescription')}
            />
          );
        }
      },
      {
        id: 'maxConsumers',
        accessorKey: 'maxConsumers',
        accessorFn: (rowOriginal) =>
          rowOriginal.maxConsumers ?? t('services.noDefined'),
        meta: { headerText: t('services.maxConsumers') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.maxConsumers')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.maxConsumersDescription')}
            />
          );
        }
      },
      {
        id: 'minAge',
        accessorKey: 'minAge',
        accessorFn: (rowOriginal) =>
          rowOriginal.minAge ?? t('services.noDefined'),
        meta: { headerText: t('services.minAge') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.minAge')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.minAgeDescription')}
            />
          );
        }
      },
      {
        id: 'maxAge',
        accessorKey: 'maxAge',
        accessorFn: (rowOriginal) =>
          rowOriginal.maxAge ?? t('services.noDefined'),
        meta: { headerText: t('services.maxAge') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.maxAge')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.maxAgeDescription')}
            />
          );
        }
      },
      {
        id: 'isExtension',
        header: t('services.isExtension'),
        cell: ({ row }) => {
          const { isExtension } = row.original;
          return isExtension ? <Check color="green" /> : <X color="red" />;
        }
      },
      {
        id: 'minPreReservationTime',
        accessorKey: 'minPreReservationTime',
        meta: { headerText: t('services.minPreReservationTime') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.minPreReservationTime')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.minPreReservationTimeDescription')}
            />
          );
        }
      },
      {
        id: 'enabled',
        accessorKey: 'enabled',
        meta: { headerText: t('services.enabled') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.enabled')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.enabledDescription')}
            />
          );
        }
      },
      {
        id: 'allowPayment',
        accessorKey: 'allowPayment',
        meta: { headerText: t('services.allowPayment') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.allowPayment')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.allowPaymentDescription')}
            />
          );
        }
      },
      {
        id: 'visibility',
        accessorKey: 'visibility',
        meta: { headerText: t('services.visibility') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.visibility')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.visibilityDescription')}
            />
          );
        }
      },
      {
        id: 'canUpdateReservations',
        accessorKey: 'canUpdateReservations',
        meta: { headerText: t('services.canUpdateReservations') },
        header: () => {
          return (
            <TableTooltip
              text={t('services.canUpdateReservations')}
              className="max-w-[6rem] line-clamp-3"
              content={t('services.canUpdateReservationsDescription')}
            />
          );
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
          const serviceId = row.original?.id;
          return (
            <div className="flex justify-end">
              <Button
                onClick={() =>
                  dispatch({ type: 'OPEN_MODAL', payload: serviceId })
                }
              >
                {t('tables.inventory.columns.buttons.availability')}
              </Button>
            </div>
          );
        }
      },
      {
        id: 'detail',
        enableHiding: false,
        cell: ({ row }) => {
          const serviceId = row.original.id;
          if (!featureFlagCrudValue) {
            return undefined;
          }
          return (
            <Link to={`./${serviceId}`} className="w-full">
              <ArrowRight />
            </Link>
          );
        }
      }
    ],
    [organization, t, featureFlagCrudValue]
  );

  const table = useReactTable({
    data: services ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    rowCount: dataServices?.total,
    pageCount: Math.ceil(
      (dataServices?.total ?? 0) / (dataServices?.limit ?? 1)
    ),
    //autoResetPageIndex: false, //turn off auto reset of pageIndex
    manualPagination: true,
    //sort config
    onSortingChange: setSorting,
    enableMultiSort: false,
    manualSorting: true,
    sortDescFirst: true,
    //filter configuration
    onColumnFiltersChange: setColumnFilters,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      pagination,
      sorting
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }
  return (
    <>
      <BlockServiceReducerContainer state={state} dispatch={dispatch} />
      <TableToolBar
        table={table}
        isFetching={isFetching}
        disabled={emptyData}
        filtering={filtering}
        setFiltering={setFiltering}
      />
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
};

export default ServicesTable;
