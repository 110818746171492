import api from '@/services/api';
import { TSchedule } from '@/services/organization';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

function usePatchOrgSchedule() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['edit-organization-schedule'],
    mutationFn: (payload: Partial<TSchedule>) => {
      return api.organization.patchSchedule(payload);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: (value) => {
      queryClient.invalidateQueries({
        queryKey: ['Getorganization']
      });
      queryClient.invalidateQueries({
        queryKey: ['organization-check-schedule']
      });
      queryClient.invalidateQueries({
        queryKey: ['services-check-schedule']
      });
      toast.success(t('toast.scheduleUpdated'));
    },
    retry: false
  });
}

export default usePatchOrgSchedule;
