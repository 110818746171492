import { Button } from '@/components/ui/button';
import { Calendar } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { ActiveMonthsModal } from '../modals/active-months-modal/ActiveMonthsModal';
import { TActiveMonths } from '@/services/organization';
import useActiveMonths from '@/hooks/useActiveMonths';
import { MonthRanges } from './MonthRanges';

interface Props {
  dateRanges: TActiveMonths;
  setDateRanges: (value: TActiveMonths) => void;
}

const ActiveMonths = ({ dateRanges, setDateRanges }: Props) => {
  const { t } = useTranslation();
  const {
    isDateModalOpen,
    openModal,
    closeModal,
    handleAddDateRange,
    handleRemoveDateRange
  } = useActiveMonths(dateRanges, setDateRanges);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-2">
        <Calendar className="w-5 h-5" />
        <h2 className="font-bold">{t('activeMonths.title')}</h2>
      </div>
      <div className="space-y-2">
        <Button variant="outline" className="w-full" onClick={openModal}>
          <Calendar className="w-4 h-4 mr-2" />
          {t('activeMonths.rangeDates')}
        </Button>
        {Object.entries(dateRanges).map(([month, ranges]) => (
          <MonthRanges
            key={month}
            month={month}
            ranges={ranges}
            handleRemoveDateRange={handleRemoveDateRange}
          />
        ))}
      </div>
      <ActiveMonthsModal
        isOpen={isDateModalOpen}
        dateRanges={dateRanges}
        onClose={closeModal}
        onAdd={handleAddDateRange}
      />
    </div>
  );
};

export default ActiveMonths;
