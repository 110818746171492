import {
  AlertCircle,
  AlertTriangle,
  CheckCircle2,
  InfoIcon
} from 'lucide-react';
import React from 'react';
import { AlertSectionProps } from './alertSection.types';

const variantStyles = {
  success: {
    bg: 'bg-green-100',
    icon: <CheckCircle2 className="text-green-600 shrink-0" />
  },
  error: {
    bg: 'bg-red-100',
    icon: <AlertTriangle className="text-red-600 shrink-0" />
  },
  warning: {
    bg: 'bg-amber-100',
    icon: <AlertCircle className="text-amber-600 shrink-0" />
  },
  info: {
    bg: 'bg-blue-100',
    icon: <InfoIcon className="text-blue-600 shrink-0" />
  }
};

const AlertSection: React.FC<AlertSectionProps> = ({ variant, text }) => {
  const { bg, icon } = variantStyles[variant] || {};

  return (
    <div className={`flex items-center px-3 py-2 ${bg} rounded w-full`}>
      {icon}
      <span className="ml-3 text-xs">{text}</span>
    </div>
  );
};

export default AlertSection;
