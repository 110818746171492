import React from 'react';

export default function AuthorizedPickupSection({
  t,
  additionalData
}: {
  t: any;
  additionalData: any;
}) {
  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold">
        {t('activity.student.authorizedPickup')}
      </h2>
      <div className="space-y-2">
        <p>
          {`${t('activity.student.authorizedName')}: ${additionalData?.authorizedToPickUp?.firstName} ${additionalData?.authorizedToPickUp?.lastName}`}
        </p>
        <p>
          {`${t('activity.student.authorizedDNI')}: ${additionalData?.authorizedToPickUp?.identification?.number}`}
        </p>
        <p>
          {`${t('activity.student.authorizedPhone')}: ${additionalData?.authorizedToPickUp?.phone}`}
        </p>
      </div>
    </div>
  );
}
