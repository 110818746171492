import useOrganizationName from '@/hooks/useOrganizationName';
import api from '@/services/api';
import { TScheduleStrigified } from '@/services/organization';
import { useQuery } from '@tanstack/react-query';

function useGetOrgCheckSchedule(serviceSchedule: TScheduleStrigified) {
  const organizationCode = useOrganizationName();

  return useQuery({
    queryKey: [
      'organization-check-schedule',
      serviceSchedule,
      organizationCode
    ],
    queryFn: () => api.organization.getCheckByActives(serviceSchedule),
    enabled: !!serviceSchedule.activeDays && !!serviceSchedule.activeMonths
  });
}

export default useGetOrgCheckSchedule;
