import { format } from 'date-fns';

export interface Filter {
  id: string;
  value: any;
}

export function buildColumnFilterURI(columnFilters: Filter[]): string {
  let columnFilterURI = '';

  columnFilters.forEach((filter) => {
    columnFilterURI += buildFilterQueryString(filter);
  });

  return columnFilterURI;
}

export function isSaleServiceIdFilter(filter: Filter): boolean {
  return filter.id === 'sale.service.id' && typeof filter.value === 'string';
}

export function isStartTimeFilter(filter: Filter): boolean {
  return filter.id === 'startTime' && typeof filter.value === 'object';
}

export function isConsumerAdditionalDataFilter(filter: Filter): boolean {
  return filter.id === 'sale.saleConsumers.consumer.additionalData';
}

export function buildFilterQueryString(filter: Filter): string {
  if (isSaleServiceIdFilter(filter)) {
    return buildSaleServiceIdFilter(filter.value);
  }

  if (isStartTimeFilter(filter)) {
    return buildStartTimeFilter(filter.value);
  }

  if (isConsumerAdditionalDataFilter(filter)) {
    return buildConsumerAdditionalDataFilter(filter.value);
  }

  return buildDefaultFilter(filter);
}

export function buildSaleServiceIdFilter(value: string): string {
  return `&filter.sale.service.id=string.eq.${value}`;
}

export function buildStartTimeFilter(value: {
  from: number;
  to?: number;
}): string {
  const { from, to } = value;
  if (from && to) {
    return `&filter.startTime=number.between.${from},${to}`;
  }
  return '';
}

export function buildConsumerAdditionalDataFilter(value: any): string {
  return `&filter.or.sale.saleConsumers.consumer.additionalData=string.like.${value}`;
}

export function buildDefaultFilter(filter: Filter): string {
  const { id, value } = filter;

  if (Array.isArray(value)) {
    return buildArrayFilter(id, value);
  }

  return buildDateRangeFilter(id, value);
}

export function buildArrayFilter(id: string, values: string[]): string {
  return `&filter.${id}=string.in.${values.join(',')}`;
}

export function buildDateRangeFilter(
  id: string,
  range: { from: number; to?: number }
): string {
  if (!range?.from) {
    return '';
  }

  const to = ensureDateRangeTo(range);

  if (!to) {
    return '';
  }

  if (id.includes('startTime')) {
    return `&filter.startTime=number.between.${range.from},${to}`;
  }

  return `&filter.${id}=date.between.${format(range.from, "yyyy-MM-dd'T'HH:mm:ss")},${format(to, "yyyy-MM-dd'T'HH:mm:ss")}`;
}

export function ensureDateRangeTo(range: {
  from: number;
  to?: number;
}): number | undefined {
  if (range.to) return range.to;

  const date = new Date(range.from);
  date.setHours(23, 0, 0, 0);
  return date.getTime();
}
