import type * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils';
// we need to change all the bg variables with the correct color
const badgeVariants = cva(
  'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
        outline: 'text-foreground',
        Completed: 'bg-sky-500 text-secondary-foreground font-bold text-sm',
        Accepted:
          'bg-[#22C55E80]   text-secondary-foreground font-bold text-sm',
        Absent: 'bg-[#F9731680] text-secondary-foreground font-bold text-sm',
        Proposed: 'bg-[#A855F780] text-secondary-foreground font-bold text-sm',
        Requested: 'bg-[#06B6D480] text-secondary-foreground font-bold text-sm',
        Pending: 'bg-slate-200 text-secondary-foreground font-bold text-sm',
        Rejected: 'bg-[#EF444480] text-secondary-foreground font-bold text-sm',
        Canceled: 'bg-slate-400 text-secondary-foreground font-bold text-sm',
        Approved:
          'bg-[#22C55E80]   text-secondary-foreground font-bold text-sm',
        warning:
          'border-yellow-500 bg-yellow-100 text-yellow-800 hover:bg-yellow-200',
        Present: 'bg-[#22C55E80]   text-secondary-foreground font-bold text-sm'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
