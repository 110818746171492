export type Role = 'Admin' | 'Monitor' | 'Secretary' | 'Customer';

export type View =
  | 'dashboard'
  | 'quickActions'
  | 'users'
  | 'calendar'
  | 'services'
  | 'inventory'
  | 'payments'
  | 'reservations'
  | 'sales'
  | 'settings'
  | 'newBooking'
  | 'servicesCategories'
  | 'inventoryCategories'
  | 'categories'
  | 'activity'
  | 'attendance'
  | 'announcements';

// Roles
const ADMIN_ROLE: Role = 'Admin';
const MONITOR_ROLE: Role = 'Monitor';
const SECRETARY_ROLE: Role = 'Secretary';

const permissions: Record<View, Role[]> = {
  dashboard: [ADMIN_ROLE],
  quickActions: [ADMIN_ROLE],
  users: [ADMIN_ROLE],
  calendar: [ADMIN_ROLE],
  services: [ADMIN_ROLE, SECRETARY_ROLE],
  inventory: [ADMIN_ROLE],
  payments: [ADMIN_ROLE, SECRETARY_ROLE],
  reservations: [ADMIN_ROLE],
  sales: [ADMIN_ROLE, SECRETARY_ROLE],
  settings: [ADMIN_ROLE],
  newBooking: [ADMIN_ROLE],
  categories: [ADMIN_ROLE],
  servicesCategories: [ADMIN_ROLE],
  inventoryCategories: [ADMIN_ROLE],
  activity: [ADMIN_ROLE, SECRETARY_ROLE],
  attendance: [ADMIN_ROLE, MONITOR_ROLE],
  announcements: [ADMIN_ROLE]
};

export const hasPermission = (role: Role, view: View) => {
  return permissions[view].includes(role);
};
