import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetReservationsPendingActions from '@/hooks/queries/reservations-api/useGetReservationsPendingActions';
import useFormattedDate from '@/hooks/useFormattedDate';
import usePersistedPaginationFiltersAndSorting from '@/hooks/usePersistedPaginationFiltersAndSorting';
import useReservationActions from '@/hooks/useReservationActions';
import { sortTimeStampStartTimeFn } from '@/lib/utils';
import type { ReservationWithAction } from '@/services/reservations';
import { getTimeZone } from '@/utils/dates';
import { getPendingAction, getReservationsTexts } from '@/utils/reservations';
import { dateInDataRange, fullName } from '@/utils/utils';
import {
  type ColumnDef,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { ArrowRight, CalendarClock, X } from 'lucide-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import AlertModal from '../../modals/alert-modal/AlertModal';
import ReprogrameDateModal from '../../modals/reprograme-date-modal/ReprogrameDateModal';
import TableTooltip from '../../tooltip/table-tooltip/TableTooltip';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import TableToolBar from './TableToolBar';

export function ReservationsPendingTable<TData, TValue>() {
  const { formattedDate } = useFormattedDate();
  const { t } = useTranslation();
  const { handleClose, handlePropose } = useReservationActions();

  const {
    pagination,
    setPagination,
    filtering,
    setFiltering,
    sorting,
    setSorting,
    columnFilters,
    setColumnFilters
  } = usePersistedPaginationFiltersAndSorting({
    defaultSorting: [{ id: 'startTime', desc: false }]
  });

  const {
    data: reservationsData,
    isLoading,
    isError
  } = useGetReservationsPendingActions();

  const organization = useGetOrganization();

  const emptyData = (reservationsData?.length ?? 0) === 0;

  const [showCancelReservation, setShowCancelReservation] = useState(false);
  const [showReprogrameModal, setShowReprogrameModal] = useState(false);

  const columns = useMemo<ColumnDef<ReservationWithAction>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={
              table.getIsAllPageRowsSelected() ||
              (table.getIsSomePageRowsSelected() && 'indeterminate')
            }
            onCheckedChange={(value) =>
              table.toggleAllPageRowsSelected(!!value)
            }
            aria-label="Select all"
            disabled={!reservationsData}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => row.toggleSelected(!!value)}
            aria-label="Select row"
            disabled={!reservationsData}
          />
        ),
        enableSorting: false,
        enableHiding: false
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id'),
        cell: ({ row }) => {
          const id = row?.original?.id;
          return <TableTooltip text={id} />;
        }
      },
      {
        id: 'user.firstName',
        accessorKey: 'user.firstName',
        accessorFn: (originalRow) =>
          `${originalRow.user.firstName} ${originalRow.user.lastName}`,
        header: t('common.user')
      },
      {
        id: 'consumers',
        accessorKey: 'consumers',
        accessorFn: (originalRow) => {
          const participants = originalRow.consumers;
          const participantsName = participants.map((participant) =>
            fullName({
              firstName: participant.firstName,
              lastName: participant.lastName
            })
          );
          return participantsName.join(', ');
        },
        enableSorting: false,
        header: t('tables.common.participants')
      },
      {
        id: 'service.name',
        accessorKey: 'service.name',
        header: t('tables.common.activity'),
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/services-sold/${reservation.sale.id}`
              }}
              className="underline"
            >
              {reservation.service.name}
            </Link>
          );
        },
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'startTime',
        accessorKey: 'startTime',
        sortingFn: sortTimeStampStartTimeFn,
        cell: ({ row }) => {
          const startTime = row.original.startTime;
          const tz = organization.data?.tz ?? '';

          return tz && startTime
            ? formattedDate({
                date: getTimeZone(Number(startTime), tz)
              })
            : '-';
        },
        header: t('tables.common.date'),
        filterFn: (row, id, value) =>
          dateInDataRange(new Date(row.getValue(id)), value)
      },
      {
        id: 'reservationStatus.name',
        accessorKey: 'reservationStatus.name',
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Badge
              variant={reservation.reservationStatus.name}
              className="justify-center h-8 justify-self-end"
            >
              {getReservationsTexts(reservation.reservationStatus.name)}
            </Badge>
          );
        },
        header: t('common.status.status'),
        filterFn: (row, id, value) => {
          return value.includes(row.getValue(id));
        }
      },
      {
        id: 'action',
        accessorKey: 'action',
        filterFn: (row, id, value) => {
          return value?.includes(row.getValue(id));
        },
        header: t('tables.reservations.pendingAction'),
        enableHiding: false,
        cell: ({ row }) => {
          const text = getPendingAction(row.original);
          return (
            <Link
              to={{
                pathname: `/reservations/${row.original.id}`
              }}
            >
              <p>{text}</p>
            </Link>
          );
        }
      },
      {
        id: 'actions',
        enableHiding: false,
        cell: ({ row }) => {
          const reservation = row.original;
          return (
            <Link
              to={{
                pathname: `/reservations/${reservation.id}`
              }}
            >
              <ArrowRight />
            </Link>
          );
        }
      }
    ],
    [formattedDate, organization.data?.tz, t, reservationsData]
  );

  const table = useReactTable({
    data: reservationsData ?? [],
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    autoResetPageIndex: false, //turn off auto reset of pageIndex
    //sort config
    enableMultiSort: false, // disable multi-sorting for the entire table
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    //filter config
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setFiltering,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    initialState: {
      columnVisibility: {
        id: false
      }
    },
    state: {
      columnFilters,
      globalFilter: filtering,
      pagination,
      sorting
    }
  });

  const reservationsChecked = table.getSelectedRowModel().rows;
  const reservationSelected = reservationsChecked[0]?.original;

  const handleRejectReservation = () => {
    if (reservationsChecked.length) {
      reservationsChecked.forEach((reservation) => {
        const reservationId = reservation.original?.id;
        handleClose(reservationId);
      });
    }
    table.toggleAllPageRowsSelected(false);
  };

  const handleReschedule = (date: number) => {
    setShowReprogrameModal(false);
    handlePropose(date, reservationSelected?.id);
    table.toggleAllPageRowsSelected(false);
  };

  if (isError) {
    return <ErrorMessage />;
  }
  return (
    <>
      <TableToolBar
        table={table}
        emptyData={emptyData}
        filtering={filtering}
        setFiltering={setFiltering}
      />
      {(table.getIsSomeRowsSelected() || table.getIsAllPageRowsSelected()) && (
        <div className="flex items-center justify-end gap-2">
          <Button
            variant={'ghost'}
            onClick={() => {
              setShowCancelReservation(true);
            }}
          >
            <X color="#EF4444" />
          </Button>
          {reservationsChecked.length === 1 ? (
            <Button
              variant={'ghost'}
              onClick={() => {
                setShowReprogrameModal(true);
              }}
            >
              <CalendarClock color="#A855F7" />
            </Button>
          ) : null}
        </div>
      )}
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />

      <AlertModal
        open={showCancelReservation}
        onOpenChange={setShowCancelReservation}
        title={t('modals.alert.title')}
        description={t('modals.alert.descriptionCancelReservation')}
        cancelText={t('modals.alert.no')}
        acceptText={t('modals.alert.yes')}
        onAccept={() => handleRejectReservation()}
      />

      {showReprogrameModal ? (
        <ReprogrameDateModal
          open={showReprogrameModal}
          onOpenChange={setShowReprogrameModal}
          onSave={(date) => handleReschedule(date)}
          serviceId={reservationSelected.service?.id}
          participants={reservationSelected.consumers.length}
          startTime={Number(reservationSelected.startTime)}
        />
      ) : null}
    </>
  );
}
