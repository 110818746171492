import { Months } from '@/hooks/useActiveMonths';
import { TActiveMonths } from '@/services/organization';
import { format } from 'date-fns';
import { z } from 'zod';

const isOverlapping = (
  existingRanges: number[][],
  newRange: number[]
): boolean => {
  return existingRanges.some(([start, end]) => {
    return newRange[0] < end && newRange[1] > start;
  });
};

const ActiveMonthsSchema = (dateRanges: TActiveMonths) => {
  return z
    .object({
      startDate: z.date({
        required_error: 'schemas.common.dateRequired'
      }),
      endDate: z.date({
        required_error: 'schemas.common.dateRequired'
      }),
      scheduleConflict: z.optional(z.boolean()) // Custom error message
    })
    .refine((data) => data.startDate <= data.endDate, {
      message: 'schedule.invertedActiveMonths',
      path: ['scheduleConflict']
    })
    .refine(
      (data) => {
        const startMonth = format(
          data.startDate,
          'MMMM'
        ).toLowerCase() as Months;
        const endMonth = format(data.endDate, 'MMMM').toLowerCase() as Months;
        const startDay = data.startDate.getDate();
        const endDay = data.endDate.getDate();

        if (startMonth === endMonth) {
          return !isOverlapping(dateRanges[startMonth], [startDay, endDay]);
        } else {
          const startRange: number[] = [
            startDay,
            new Date(
              data.startDate.getFullYear(),
              data.startDate.getMonth() + 1,
              0
            ).getDate()
          ];
          const endRange: number[] = [1, endDay];
          return (
            !isOverlapping(dateRanges[startMonth], startRange) &&
            !isOverlapping(dateRanges[endMonth], endRange)
          );
        }
      },
      {
        message: 'schedule.overlappingActiveMonths',
        path: ['scheduleConflict']
      }
    );
};

export default ActiveMonthsSchema;

type TForm = ReturnType<typeof ActiveMonthsSchema>;
export type ActiveMonthsValues = z.infer<TForm>;
