import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import MultipleSelector from '@/components/ui/multiple-selector';
import { Textarea } from '@/components/ui/textarea';
import inventoryCategorySchema from '@/schemas/inventoryCategory-form';
import { TSports } from '@/services/sports';
import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type TForm = ReturnType<typeof inventoryCategorySchema>;
export type InventoryCategoryValues = z.infer<TForm>;
type FormInventoryCategoryProps = {
  onSubmit: (data: InventoryCategoryValues) => void;
  isPending: boolean;
  sports: TSports[];
  defaultValues?: InventoryCategoryValues;
  onDelete?: () => void;
  isPendingDelete?: boolean;
};

const FormInventoryCategory = ({
  defaultValues,
  isPending,
  onSubmit,
  sports,
  onDelete,
  isPendingDelete
}: FormInventoryCategoryProps) => {
  const form = useForm<InventoryCategoryValues>({
    resolver: zodResolver(inventoryCategorySchema()),
    defaultValues: defaultValues ?? undefined,
    mode: 'onChange'
  });

  const hasChanged = !form.formState.isDirty;

  const SPORTS_OPTIONS = sports.map((sport) => {
    return { label: sport.name, value: sport.id };
  });

  const renderButtonContent = (isEdit: boolean, isPending: boolean) => {
    if (isPending) {
      return <Loader className="w-4 h-4 animate-spin" />;
    }
    if (isEdit) {
      return t('form.update');
    }
    return t('form.create');
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`bg-accent p-8 rounded-md w-full`}
      >
        <div className="flex flex-col gap-3">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('form.name')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('form.description')}
                  <span className="ml-2 text-sm font-light text-gray-500">
                    {t('form.optional')}
                  </span>
                </FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="sports"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {t('tables.inventoryCategory.serviceCategories')}
                </FormLabel>
                <FormDescription>
                  {t('tables.inventoryCategory.serviceCategoriesDescription')}
                </FormDescription>
                <FormControl>
                  <MultipleSelector
                    {...field}
                    defaultOptions={SPORTS_OPTIONS}
                    emptyIndicator={
                      <p className="text-center text-gray-600 text-md dark:text-gray-400">
                        {t('form.noResaultFound')}
                      </p>
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end gap-2 mt-8">
          {onDelete && (
            <Button
              type="button"
              disabled={isPendingDelete || !!defaultValues?.sports?.length}
              variant={'destructive'}
              onClick={onDelete}
            >
              {isPendingDelete ? (
                <Loader className="w-4 h-4 animate-spin" />
              ) : (
                t('form.delete')
              )}
            </Button>
          )}
          <Button type="submit" disabled={isPending || hasChanged}>
            {renderButtonContent(!!defaultValues, isPending)}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormInventoryCategory;
