import useOrganizationName from '@/hooks/useOrganizationName';
import { TOrganization } from '@/services/organization';
import { useQueryClient } from '@tanstack/react-query';

const useGetOrganizationFromCache = () => {
  const queryClient = useQueryClient();
  const organizationName = useOrganizationName();
  return queryClient.getQueryData<TOrganization>([
    'Getorganization',
    organizationName
  ]);
};

export default useGetOrganizationFromCache;
