import ActiveDays from '@/components/common/active-periods/ActiveDays';
import ActiveMonths from '@/components/common/active-periods/ActiveMonths';
import { Button } from '@/components/ui/button';
import usePatchOrgSchedule from '@/hooks/queries/organization-api/usePatchOrgSchedule';
import { TActiveDays, TActiveMonths, TSchedule } from '@/services/organization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultSchedule: TSchedule;
}

export const OrganizationSchedule = ({ defaultSchedule }: Props) => {
  const [dateRanges, setDateRanges] = useState<TActiveMonths>(
    defaultSchedule.activeMonths
  );
  const [hoursRanges, setHoursRanges] = useState<TActiveDays>(
    defaultSchedule.activeDays
  );

  const { mutate } = usePatchOrgSchedule();
  const { t } = useTranslation();

  const handleOnSubmit = () => {
    mutate({
      activeDays: hoursRanges,
      activeMonths: dateRanges
    });
  };

  return (
    <div className="flex flex-col gap-2 my-5">
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-2">
        <ActiveMonths dateRanges={dateRanges} setDateRanges={setDateRanges} />
        <ActiveDays hoursRanges={hoursRanges} setHoursRanges={setHoursRanges} />
      </div>
      <Button className="self-end" onClick={handleOnSubmit}>
        {t('common.save')}
      </Button>
    </div>
  );
};
