import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { RootStore } from './root-store.types';
import { createAnnouncementsSlice } from './slices/announcements/announcements-slice';
import { createAuthSlice } from './slices/auth/auth-slice';
import { createCounterSlice } from './slices/counter/counter-slice';
import { createDashboardSlice } from './slices/dashboard/dashboard-slice';
import { createModalsSlice } from './slices/modals/modals-slice';
import { createSessionSlice } from './slices/session/session-slice';
import { createStepServiceSlice } from './slices/step-service/step-service';
import { createStepperSlice } from './slices/stepper/stepper-slice';

export const useRootStore = create<RootStore>()(
  persist(
    // zustandActions refers to zustand inner methos as set,get,etc...
    (...zustandActions): any => ({
      ...createCounterSlice(...zustandActions),
      ...createAuthSlice(...zustandActions),
      ...createSessionSlice(...zustandActions),
      ...createDashboardSlice(...zustandActions),
      ...createModalsSlice(...zustandActions),
      ...createStepperSlice(...zustandActions),
      ...createStepServiceSlice(...zustandActions),
      ...createAnnouncementsSlice(...zustandActions)
    }),
    {
      name: 'root-store',
      onRehydrateStorage: (state) => {
        // console.log('hydration starts', state);

        // optional
        return (state, error) => {
          if (error) {
            console.log('an error happened during hydration', error);
          } else {
            // console.log('hydration finished', state);
          }
        };
      }
    }
  )
);
