import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar
} from '@/components/ui/sidebar';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';
import useGetUser from '@/hooks/queries/users-api/useGetUser';
import { hasPermission, type View } from '@/utils/roles';
import {
  BarChart,
  Box,
  CalendarClock,
  CalendarDaysIcon,
  ChevronRight,
  DollarSign,
  FileCheck2,
  HandHeart,
  LayoutGrid,
  Megaphone,
  PersonStanding,
  Settings,
  UserCheck,
  UserRoundPlus,
  UsersIcon,
  Zap
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import LanguageChanger from '../navbar/LanguageChangerButton/LanguageChanger';
import type { TMenuItem } from './sidebarV2.types';

export function AppSidebar() {
  const { data: organization } = useGetOrganization();
  const { data: userData } = useGetUser();
  const location = useLocation();
  const { t } = useTranslation();
  const { open, openMobile, setOpen, setOpenMobile, isMobile } = useSidebar();

  // Ticket Jira: https://incubator-np.atlassian.net/browse/OC-1201
  // Issue reported on GitHub: https://github.com/shadcn-ui/ui/issues/5642
  if (!openMobile && isMobile) {
    return null;
  }

  const defaultView: { [key: string]: boolean } = {
    dashboard: true,
    quickActions: true,
    users: true,
    calendar: true,
    services: true,
    inventory: true,
    payments: true,
    reservations: true,
    sales: true,
    settings: true,
    newBooking: true,
    categories: true,
    servicesCategories: true,
    inventoryCategories: true,
    activity: false,
    attendance: false,
    announcements: false
  };

  const viewsPerOrg = organization?.additionalData
    ? JSON.parse(organization?.additionalData)
    : {};

  const isActive = (value: string) => {
    const { name: userRole } = userData?.userType || { name: 'Customer' };

    if (!hasPermission(userRole, value as View)) {
      return false;
    }

    return viewsPerOrg[value] ?? defaultView[value];
  };

  const MENU_ITEMS: TMenuItem[] = [
    {
      Icon: Zap,
      title: t('sidebar.quickActions'),
      link: '/quick-actions',
      active: isActive('quickActions')
    },
    {
      Icon: BarChart,
      title: t('sidebar.dashboard'),
      link: '/dashboard',
      active: isActive('dashboard')
    },
    {
      Icon: UserRoundPlus,
      title: t('sidebar.newBooking'),
      link: '/new-booking',
      active: isActive('newBooking')
    },
    {
      Icon: FileCheck2,
      title: t('sidebar.sales'),
      link: '/services-sold',
      active: isActive('sales')
    },
    {
      Icon: PersonStanding,
      title: t('sidebar.activity'),
      link: '/activity',
      active: isActive('activity')
    },
    {
      Icon: UserCheck,
      title: t('sidebar.attendance'),
      link: '/attendance',
      active: isActive('attendance')
    },
    {
      Icon: CalendarClock,
      title: t('sidebar.reservations'),
      link: '/reservations',
      active: isActive('reservations')
    },
    {
      Icon: UsersIcon,
      title: t('sidebar.users'),
      link: '/users',
      active: isActive('users')
    },
    {
      Icon: DollarSign,
      title: t('sidebar.payments'),
      link: '/payments',
      active: isActive('payments')
    },

    {
      Icon: CalendarDaysIcon,
      title: t('sidebar.calendar'),
      link: '/calendar',
      active: isActive('calendar')
    },
    {
      Icon: HandHeart,
      title: t('sidebar.services'),
      link: '/services',
      active: isActive('services'),
      sub: [
        {
          Icon: HandHeart,
          title: t('sidebar.services'),
          link: '/services',
          active: isActive('services')
        },
        {
          Icon: LayoutGrid,
          title: t('sidebar.categories'),
          link: '/services/categories',
          active: isActive('servicesCategories')
        }
      ]
    },
    {
      Icon: Box,
      title: t('sidebar.inventory'),
      link: '/inventory',
      active: isActive('inventory'),
      sub: [
        {
          Icon: Box,
          title: t('sidebar.inventory'),
          link: '/inventory',
          active: isActive('inventory')
        },
        {
          Icon: LayoutGrid,
          title: t('sidebar.categories'),
          link: '/inventory/categories',
          active: isActive('inventoryCategories')
        }
      ]
    },
    {
      Icon: Settings,
      title: t('sidebar.settings'),
      link: '/settings',
      active: isActive('settings')
    },
    {
      Icon: Megaphone,
      title: t('sidebar.announcements'),
      link: '/announcements',
      active: isActive('announcements')
    }
  ];

  return (
    <Sidebar collapsible="icon" className="mt-[60px]">
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {MENU_ITEMS.filter((item) => item.active).map((item) => {
              const isLinkActive =
                '/' + location.pathname.split('/')[1] === item.link;
              const hasSubLinks = !!item.sub;
              if (hasSubLinks) {
                return (
                  <Collapsible
                    asChild
                    className="group/collapsible"
                    defaultOpen={isLinkActive}
                    key={item.title}
                  >
                    <SidebarMenuItem>
                      <CollapsibleTrigger asChild>
                        <SidebarMenuButton
                          tooltip={item.title}
                          className={`py-3 px-7 ${isLinkActive ? 'text-primary' : ''}`}
                          onClick={() => !open && setOpen(true)}
                        >
                          {item.Icon && <item.Icon />}
                          <h3 className="ml-3 text-start">{item.title}</h3>
                          <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                        </SidebarMenuButton>
                      </CollapsibleTrigger>
                      <CollapsibleContent>
                        <SidebarMenuSub>
                          {item.sub?.map((subItem) => (
                            <SidebarMenuSubItem key={subItem.title}>
                              <SidebarMenuSubButton asChild>
                                <Link
                                  to={subItem.link}
                                  onClick={() => setOpenMobile(false)}
                                >
                                  {subItem.Icon && (
                                    <subItem.Icon
                                      className={`${location.pathname === subItem.link ? '!text-primary' : ''}`}
                                    />
                                  )}
                                  <h3
                                    className={`ml-3 text-start ${location.pathname === subItem.link ? '!text-primary' : ''}`}
                                  >
                                    {subItem.title}
                                  </h3>
                                </Link>
                              </SidebarMenuSubButton>
                            </SidebarMenuSubItem>
                          ))}
                        </SidebarMenuSub>
                      </CollapsibleContent>
                    </SidebarMenuItem>
                  </Collapsible>
                );
              }
              return (
                <SidebarMenuItem
                  key={item.title}
                  className={`flex align-middle hover:text-primary ${isLinkActive ? 'text-primary' : ''}`}
                >
                  <SidebarMenuButton asChild className="py-3 px-7">
                    <Link to={item.link} onClick={() => setOpenMobile(false)}>
                      {item.Icon && <item.Icon />}
                      <h3 className="ml-3 text-start">{item.title}</h3>
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              );
            })}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="py-3 px-7 md:hidden">
        <LanguageChanger />
      </SidebarFooter>
    </Sidebar>
  );
}
