import { Button } from '@/components/ui/button';
import { Days } from '@/hooks/useActiveDays';
import { TActiveDays } from '@/services/organization';
import { Clock, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface Props {
  hoursRanges: TActiveDays;
  setSelectedDay: (value: Days) => void;
  setIsTimeModalOpen: (value: boolean) => void;
  handleRemoveTimeRange: (day: Days, index: number) => void;
}

export const DayRanges = ({
  hoursRanges,
  setSelectedDay,
  setIsTimeModalOpen,
  handleRemoveTimeRange
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.entries(hoursRanges).map(([day, timeRanges]) => (
        <div key={day} className="p-3 bg-white border rounded-md">
          <div className="flex items-center justify-between mb-2">
            <span className="font-medium">{t(`days.${day}`)}</span>
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setSelectedDay(day as Days);
                setIsTimeModalOpen(true);
              }}
            >
              <Clock className="w-4 h-4 mr-2" />
              {t('activeHours.rangeHours')}
            </Button>
          </div>
          <div className="flex flex-wrap gap-2">
            {timeRanges.length === 0 ? (
              <p className="text-sm">{t('schedule.noActivityActiveDays')}</p>
            ) : (
              timeRanges
                .sort(([startA], [startB]) => startA - startB)
                .map(([startHour, endHour], index) => (
                  <div
                    key={`${startHour}:${endHour}_${index}`}
                    className="flex items-center gap-2 px-2 py-1 rounded-3xl bg-primary text-primary-foreground hover:bg-primary/90"
                  >
                    <p className="text-sm">
                      {startHour.toString().padStart(2, '0')}:00 -{' '}
                      {endHour.toString().padStart(2, '0')}:00
                    </p>
                    <span
                      className="cursor-pointer"
                      onClick={() => handleRemoveTimeRange(day as Days, index)}
                    >
                      <X className="w-4 h-4" />
                    </span>
                  </div>
                ))
            )}
          </div>
        </div>
      ))}
    </>
  );
};
