import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { QueryParams } from '@/utils/utils';
import { t } from 'i18next';

export function ConsumerStatusModal({
  open,
  onOpenChange,
  reservarionId,
  handleCompleted
}: Readonly<{
  open: boolean;
  onOpenChange: (state: boolean) => void;
  reservarionId: string;
  handleCompleted: (reservationId: string, query?: QueryParams) => void;
}>) {
  const handlePresent = () => {
    handleCompleted(reservarionId, { markAllPresent: true });
    onOpenChange(false);
  };
  const handleWithoutCompleted = () => {
    handleCompleted(reservarionId);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="gap-6 ">
        <DialogHeader>
          <DialogTitle className="mb-2 text-center">
            {t('modals.changeConsumerStatus.title')}
          </DialogTitle>
          <DialogDescription className="text-center">
            {t('modals.changeConsumerStatus.description')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <div className="flex flex-col w-full gap-4">
            <Button onClick={() => handlePresent()}>
              {t('modals.changeConsumerStatus.yes')}
            </Button>
            <Button variant="outline" onClick={() => handleWithoutCompleted()}>
              {t('modals.changeConsumerStatus.no')}
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
