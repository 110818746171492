import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import cancelSaleSchema from '@/schemas/cancel-sales';
import { zodResolver } from '@hookform/resolvers/zod';
import { Loader } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CancelSaleModalT } from './cancel-sale-modal.types';

const CancelSaleModal = ({
  open,
  onOpenChange,
  loading,
  onSave
}: CancelSaleModalT) => {
  const { t } = useTranslation();
  const form = useForm({
    resolver: zodResolver(cancelSaleSchema()),
    defaultValues: {
      observation: ''
    }
  });

  return (
    <Dialog
      open={open}
      onOpenChange={(openState) => {
        onOpenChange(openState);
        if (!openState) form.reset();
      }}
    >
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="mb-5">
            {t('modals.canceledServiceSold.title')}
          </DialogTitle>
          <DialogDescription>
            {t('modals.canceledServiceSold.clarifications')}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSave)}
            className="flex flex-col w-full"
          >
            <FormField
              control={form.control}
              name="observation"
              render={({ field }) => (
                <FormItem className="mb-8">
                  <FormLabel>
                    {t('modals.canceledServiceSold.observation')}
                  </FormLabel>
                  <FormControl>
                    <Textarea className="w-full resize-none" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={loading} variant="destructive">
              {loading ? (
                <Loader className="w-4 h-4 mr-2 animate-spin" />
              ) : (
                t('modals.canceledServiceSold.title')
              )}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CancelSaleModal;
