import { BlockServiceForm } from '@/components/forms/form-block-services/FormBlockServices';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useGetServicesById } from '@/hooks/queries/services-api/useGetOrganizationServices';
import useGetServiceActiveDates from '@/hooks/queries/services-api/useGetServiceActiveDates';
import usePostClousureCheck from '@/hooks/queries/services-api/usePostClosureCheck';
import { format } from 'date-fns';
import { t } from 'i18next';
import { differenceWith, isEqual } from 'lodash';
import { Loader } from 'lucide-react';
import { useEffect } from 'react';
import type { BlockServiceModalT } from '../block-service-reducer/block-services-modals-types';
import type { TCheckModalState } from '@/lib/utils';

export function BlockServiceModal({
  state,
  dispatch
}: Readonly<BlockServiceModalT>) {
  const serviceId = String(state.serviceId);

  const {
    mutate: postClousureCheck,
    isPending: isPendingClosureCheck,
    data: closureCheck
  } = usePostClousureCheck((checkModal) => {
    dispatch({
      type: 'SET_CHECK_MODAL',
      payload: checkModal as TCheckModalState
    });
  });

  const { data: service } = useGetServicesById(serviceId);
  const { data: availableDates, isFetching } =
    useGetServiceActiveDates(serviceId);
  const { data: availableDatesSchool, isFetching: isFetchingDatesSchool } =
    useGetServiceActiveDates();
  const isLoading =
    !!availableDates && !!service?.id && !isFetching && !isFetchingDatesSchool;

  useEffect(() => {
    dispatch({ type: 'SET_CLOSURE_CHECK', payload: closureCheck });
  }, [closureCheck, dispatch]);

  return (
    <Dialog
      open={state.checkModal.state ? false : state.state}
      onOpenChange={() => dispatch({ type: 'CLOSE_MODAL' })}
    >
      <DialogContent className="sm:max-w-[425px] pr-0 ">
        <DialogHeader>
          <DialogTitle>
            {t('common.blockService')}:
            <span className="font-normal"> {service?.name || '...'}</span>
          </DialogTitle>
        </DialogHeader>
        <ScrollArea className="max-h-[80vh] pr-4">
          {isLoading ? (
            <BlockServiceForm
              availableDatesSchool={availableDatesSchool}
              availableDates={availableDates}
              serviceId={service.id ?? undefined}
              isPending={isPendingClosureCheck}
              onSubmit={(values, form) => {
                const originalValues =
                  availableDates[format(values.date, 'dd-MM-yyyy')];
                const changedValues = differenceWith(
                  values.time,
                  originalValues,
                  isEqual
                );

                if (changedValues.length) {
                  const closures = {
                    [format(values.date, 'dd-MM-yyyy')]: changedValues
                  };
                  dispatch({ type: 'SET_CLOSURES', payload: closures });
                  serviceId
                    ? postClousureCheck({ closures, serviceId })
                    : postClousureCheck({ closures });
                } else {
                  form.setError(
                    'time',
                    { type: 'focus', message: 'Realiza un cambio' },
                    { shouldFocus: true }
                  );
                }
              }}
            />
          ) : (
            <div className="flex justify-center">
              <Loader className="w-4 h-4 mr-2 animate-spin" />
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}
