import AlertSection from '@/components/common/alert/AlertSection';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import ServiceForm from '@/components/forms/form-service/ServiceForm';
import { ServicesFormValues } from '@/components/forms/form-service/ServiceForm.types';
import ServiceSchedule from '@/components/forms/form-service/ServiceSchedule';
import { Skeleton } from '@/components/ui/skeleton';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useGetOrgCheckSchedule from '@/hooks/queries/organization-api/useGetOrgCheckSchedule';
import useGetServiceById from '@/hooks/queries/services-api/useGetServiceById';
import usePatchService from '@/hooks/queries/services-api/usePatchService';
import useGetOrganizationSports from '@/hooks/queries/sports-api/useGetOrganizationSports';
import { getChangedValues } from '@/utils/forms';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const EditService = () => {
  const { t } = useTranslation();
  const { id: serviceId } = useParams();
  const { data: service, isError, isLoading } = useGetServiceById(serviceId);
  const {
    data: sport,
    isError: isErrorSport,
    isLoading: isLoadingSport
  } = useGetOrganizationSports();
  const { data: orgCheckSchedule } = useGetOrgCheckSchedule({
    activeDays: service?.activeDays,
    activeMonths: service?.activeMonths
  });
  const { mutate, isPending } = usePatchService();
  const isScheduleConflict = orgCheckSchedule !== null;

  console.log(orgCheckSchedule, isScheduleConflict);

  if (isLoading || isLoadingSport) {
    return (
      <>
        <GoBack />
        <Skeleton className="w-full h-[50vh]" />
      </>
    );
  }

  if (isError || isErrorSport) {
    return (
      <>
        <GoBack />
        <NoData />
        <ErrorMessage />
      </>
    );
  }

  if (!service || !sport) {
    return (
      <>
        <GoBack />
        <NoData />
      </>
    );
  }

  const defaultValues = {
    name: service.name,
    description: service.description,
    price: service.price,
    reservationPct: service.reservationPct,
    minPreReservationTime: service.minPreReservationTime,
    sport: service.sport.id,
    allowPayment: service.allowPayment,
    visibility: service.visibility,
    canUpdateReservations: service.canUpdateReservations,
    minConsumers: service.minConsumers,
    maxConsumers: service.maxConsumers,
    minAge: service.minAge || 0,
    maxAge: service.maxAge || 0
  };

  const onSubmit = (data: ServicesFormValues) => {
    const onlyChangedData = getChangedValues<Partial<ServicesFormValues>>(
      defaultValues,
      data
    );
    mutate({ payload: onlyChangedData, serviceId: service.id });
  };

  const defaultSchedule = {
    activeDays: JSON.parse(service.activeDays),
    activeMonths: JSON.parse(service.activeMonths)
  };

  return (
    <div className="mb-4">
      <GoBack />
      <div className="p-5 space-y-6 rounded-md bg-accent">
        <p className="font-bold text-md text-primary">
          {t('form.updateService')} - {service.name}
        </p>
        <Tabs defaultValue="serviceForm">
          <TabsList className="mb-4">
            <TabsTrigger value="serviceForm">{t('common.details')}</TabsTrigger>
            <TabsTrigger value="schedule">{t('common.schedules')}</TabsTrigger>
          </TabsList>
          {isScheduleConflict && (
            <AlertSection
              variant="warning"
              text={t('services.scheduleConflict')}
            />
          )}
          <div className="mt-6">
            <TabsContent value="serviceForm">
              <ServiceForm
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                isPending={isPending}
                sport={sport}
              />
            </TabsContent>
            {serviceId && (
              <TabsContent value="schedule">
                <ServiceSchedule
                  serviceId={serviceId}
                  defaultSchedule={defaultSchedule}
                />
              </TabsContent>
            )}
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default EditService;
