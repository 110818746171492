import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchConsumerStatus = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationKey: ['patchConsumerStatus'],
    mutationFn: ({
      reservationId,
      data
    }: {
      reservationId: string;
      data: { consumer: string; status: 'Present' | 'Absent' };
    }) => {
      return api.reservations.patchConsumerStatus({
        reservationId,
        body: { consumer: data.consumer, status: data.status }
      });
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'reservation' ||
          query.queryKey[0] === 'reservations'
      });
    },
    retry: false
  });
};

export default usePatchConsumerStatus;
