import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import type { TCheckReservationsModal } from './checkReservationsModal.types';

const CheckReservationsModal = ({
  state,
  dispatch,
  onSubmit
}: TCheckReservationsModal) => {
  const { t } = useTranslation();
  const reservations = state.closureCheck;
  const closures = state.closures;

  const title = reservations?.length
    ? t('alert.checkReservation.checkReservation')
    : t('alert.checkReservation.noReservations');

  const description = reservations?.length
    ? t('alert.checkReservation.description')
    : t('alert.checkReservation.noReservationsDescription');

  /**
   * Si no hay bloqueos, se envía el update.
   * Si hay bloqueos, se abre el modal de detalle de bloqueos.
   */
  const handleOnClick = () => {
    if (
      Object.values(closures)[0].filter((item) => item.status === 'blocked')
        .length === 0
    ) {
      onSubmit({ closures });
      dispatch({ type: 'CLOSE_MODAL' });
    } else {
      dispatch({ type: 'SET_MODAL_STEP', payload: 2 });
      dispatch({ type: 'OPEN_MODAL', payload: String(state.serviceId) });
    }
  };

  return (
    <AlertDialog open={state.checkModal.state}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        {reservations?.length ? (
          <div className="divide-y">
            {reservations.map((reservation) => {
              const fullName = `${reservation.user.firstName} ${reservation.user.lastName}`;
              const serviceName = reservation.service.name;
              return (
                <Link
                  to={`/reservations/${reservation.id}`}
                  key={reservation.id + reservation.user.id}
                >
                  <div className="p-4 rounded hover:bg-gray-50 active:bg-gray-200">
                    <div className="flex gap-1">
                      <p className="font-bold">{t('common.service') + ':'}</p>
                      <p>{serviceName}</p>
                    </div>
                    <div className="flex gap-1">
                      <p className="font-bold">
                        {t('alert.checkReservation.name')}
                      </p>
                      <p>{fullName}</p>
                    </div>
                    <div className="flex gap-1">
                      <p className="font-bold">
                        {t('alert.checkReservation.saleId')}
                      </p>
                      <p>{reservation.sale.id}</p>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : undefined}
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => dispatch({ type: 'CLOSE_MODAL' })}>
            {t('common.cancel')}
          </AlertDialogCancel>
          <AlertDialogAction onClick={handleOnClick}>
            {t('common.next')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default CheckReservationsModal;
