import InputSelect from '@/components/common/input/input-select/InputSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import DeleteAnnouncementsModal from '@/components/common/modals/delete-announcements-modal/DeleteAnnouncementsModal';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import announcementsSchema from '@/schemas/announcements-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { addYears } from 'date-fns';
import { t } from 'i18next';
import { Loader } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

type TForm = ReturnType<typeof announcementsSchema>;
export type AnnouncementsValues = z.infer<TForm>;
type FormAnnouncementsProps = {
  onSubmit: (data: AnnouncementsValues) => void;
  isPending: boolean;
  defaultValues?: AnnouncementsValues;
  isEdit?: boolean;
  announcementId?: string;
};

export const options = [
  {
    value: 'all',
    label: 'Todos'
  },
  {
    value: 'Admin',
    label: 'Admin'
  },
  {
    value: 'Monitor',
    label: 'Monitores'
  },
  {
    value: 'Secretary',
    label: 'Secretaría'
  }
];

const FormAnnouncements = ({
  defaultValues,
  isPending,
  onSubmit,
  isEdit,
  announcementId
}: FormAnnouncementsProps) => {
  const form = useForm<AnnouncementsValues>({
    resolver: zodResolver(announcementsSchema()),
    defaultValues: defaultValues ?? undefined
  });

  const text = isEdit ? t('form.update') : t('form.create');
  const [deleteAnnouncements, setDeleteAnnouncements] = useState(false);

  const openDeleteModal = () => {
    setDeleteAnnouncements(true);
  };

  const hasChanged = !form.formState.isDirty;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={`bg-accent p-8 rounded-md w-full`}
      >
        <div className="flex flex-col gap-3">
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('announcements.title')}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('announcements.description')}</FormLabel>
                <FormControl>
                  <Textarea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem className="flex flex-col ">
                <FormLabel>{t('announcements.startTime')}</FormLabel>
                <FormControl>
                  <CalendarModal
                    value={field.value}
                    placeholder={t('announcements.startTime')}
                    onAccept={(value) => {
                      field.onChange(value);
                    }}
                    initialFocus
                    disabled={(date) => {
                      const today = new Date();
                      today.setHours(0, 0, 0, 0);
                      return date < today;
                    }}
                    fromDate={new Date()}
                    toDate={addYears(new Date(), 1)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endTime"
            render={({ field }) => (
              <FormItem className="flex flex-col ">
                <FormLabel>{t('announcements.endTime')}</FormLabel>
                <FormControl>
                  <CalendarModal
                    value={field.value}
                    placeholder={t('announcements.endTime')}
                    onAccept={(value) => {
                      field.onChange(value);
                    }}
                    initialFocus
                    disabled={(date) => date < new Date()}
                    fromDate={new Date()}
                    toDate={addYears(new Date(), 1)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="audience"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('announcements.audience')}</FormLabel>
                <FormControl>
                  <InputSelect
                    placeholder={t('announcements.selectUserType')}
                    onValueChange={field.onChange}
                    value={field.value}
                    // TODO: Get all userTypes from API after roles & permissions implementation
                    options={options}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex justify-end gap-4">
          <Button
            type="submit"
            className="mt-8"
            disabled={isPending || hasChanged}
          >
            {isPending ? <Loader className="w-4 h-4 animate-spin" /> : text}
          </Button>
          {isEdit && (
            <>
              <Button
                className="mt-8"
                variant="destructive"
                type="button"
                onClick={openDeleteModal}
              >
                {t('form.delete')}
              </Button>
              <DeleteAnnouncementsModal
                open={deleteAnnouncements}
                onOpenChange={setDeleteAnnouncements}
                announcementId={announcementId ?? ''}
              />
            </>
          )}
        </div>
      </form>
    </Form>
  );
};

export default FormAnnouncements;
