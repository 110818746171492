import AnnouncementsTable from '@/components/common/tables/announcements-table/AnnouncementsTable';

const Announcements = () => {
  return (
    <div className="w-full">
      <AnnouncementsTable />
    </div>
  );
};

export default Announcements;
