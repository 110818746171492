import { AnnouncementCard } from '@/components/common/cards/announcement-card/AnnouncementCard';
import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import { Skeleton } from '@/components/ui/skeleton';
import useGetAnnouncements from '@/hooks/queries/announcements-api/useGetAnnouncements';
import useGetUserFromCache from '@/hooks/queries/users-api/useGetUserFromCache';
import { TAnnouncement } from '@/services/announcements';
import { useRootStore } from '@/stores/root-store';
import { useEffect, useState } from 'react';

export const Announcements = () => {
  const { data: announcements, isLoading, isError } = useGetAnnouncements();
  const user = useGetUserFromCache();
  const storedAnnouncements = useRootStore((store) => store.announcements);
  const setAnnouncement = useRootStore((store) => store.setAnnouncement);
  const setIsHide = useRootStore((store) => store.setIsHide);
  const [announcementsWithinToday, setAnnouncementsWithinToday] = useState<
    TAnnouncement[]
  >([]);

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    if (announcements) {
      const announcementsEnabled = announcements.filter((a) => {
        const startTime = a.startTime;
        const endTime = a.endTime ?? Number.POSITIVE_INFINITY;
        return today >= startTime && today <= endTime;
      });

      setAnnouncementsWithinToday(announcementsEnabled);

      announcementsEnabled.forEach((a) => {
        setAnnouncement({ id: a.id, isHide: false });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements]);

  if (isLoading || !user) {
    return <Skeleton className="w-full h-20 mb-4" />;
  }

  if (isError) {
    return <ErrorMessage />;
  }

  if (!announcements) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      {announcementsWithinToday
        .filter(
          (a) => a.audience === user.userType?.name || a.audience === 'all'
        )
        .map((a) => {
          const storedAnnouncement = (storedAnnouncements || []).find(
            (sa) => sa.id === a.id
          );
          if (storedAnnouncement?.isHide) {
            return null;
          }

          return (
            <AnnouncementCard
              key={a.id}
              id={a.id}
              title={a.title}
              description={a.description}
              action={setIsHide}
            />
          );
        })}
    </div>
  );
};
