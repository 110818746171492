import { Button } from '@/components/ui/button';
import { Pencil } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function CardHeaderSection({ t }: { t: any }) {
  return (
    <div className="flex items-start justify-between mb-8">
      <h2 className="text-xl font-semibold">
        {t('activity.student.personalData')}
      </h2>
      <Button asChild variant="outline" size="sm">
        <Link to={'edit'}>
          <Pencil className="w-4 h-4 mr-2" />
          {t('form.edit')}
        </Link>
      </Button>
    </div>
  );
}
