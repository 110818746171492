import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import CalendarModal from '@/components/common/modals/calendar-modal/CalendarModal';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { ageGroups, parentsVEP } from '@/lib/utils';
import { getDefaultValue } from '@/utils/users';
import type React from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const UserForm: React.FC<{ form: UseFormReturn<any, any, undefined> }> = ({
  form
}) => {
  const { t } = useTranslation();
  return (
    <div className="grid gap-6 md:grid-cols-2">
      <FormField
        control={form.control}
        name={'user.firstName'}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.firstName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.lastName'}
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('stepParticipants.form.lastName')}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.identification.type"
        render={({ field }) => {
          return (
            <FormItem>
              <IdentificationTypeSelect
                defaultValue={field?.value}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
                field={field}
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.identification.number"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>
                {t('stepParticipants.form.identificationNumber')}
              </FormLabel>
              <FormControl>
                <Input
                  placeholder={t('stepParticipants.form.id')}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.location"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.address')}</FormLabel>
            <FormControl>
              <Input {...field} value={field?.value || ''} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={'user.birthdate'}
        render={({ field }) => {
          return (
            <FormItem className="flex flex-col ">
              <FormLabel>{t('stepParticipants.form.birth')}</FormLabel>
              <CalendarModal
                value={field.value}
                placeholder={t('stepParticipants.form.birth')}
                onAccept={(value) => {
                  field.onChange(value);
                }}
                initialFocus
                dropDownPicker
              />
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.insurance"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('form.medicalCoverage')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={'OSDE, Swiss Medical, etc'}
                  {...field}
                  value={field?.value || ''}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.group"
        render={({ field }) => {
          return (
            <FormItem>
              <FormLabel>{t('activity.student.group')}</FormLabel>
              <FormControl>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {Object.keys(ageGroups).map((group) => (
                      <SelectItem key={group} value={group}>
                        {ageGroups[group as keyof typeof ageGroups]}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name="user.size"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.tShirtSize')}</FormLabel>
            <FormControl>
              <Input
                placeholder={'2 al 12 / XS al XXL'}
                {...field}
                value={field?.value || ''}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="user.holidayDate"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{t('form.holidayDate')}</FormLabel>
            <FormControl>
              <Input
                placeholder={'Del 16/01 al 07/02'}
                {...field}
                value={field?.value || ''}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name={'user.isExtension'}
        render={({ field }) => {
          const defaultValue = getDefaultValue(field.value);
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('common.isExtension')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    ref={field.ref}
                    onValueChange={(value) => field.onChange(value === 'yes')}
                    defaultValue={defaultValue}
                    className="flex items-center mt-0 space-x-2"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="yes" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.yes')}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="no" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.no')}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.isEmployeePartner'}
        render={({ field }) => {
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('isEmployeePartner')}</FormLabel>
                <FormControl>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger ref={field.ref}>
                        <SelectValue
                          placeholder={t('isEmployeePartnerPlaceholder')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {parentsVEP.map((parent) => (
                        <SelectItem key={parent} value={parent}>
                          {parent}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          );
        }}
      />
      <FormField
        control={form.control}
        name={'user.isPartner'}
        render={({ field }) => {
          const defaultValue = getDefaultValue(field.value);
          return (
            <FormItem>
              <div className="flex flex-row items-end gap-5">
                <FormLabel>{t('isPartner')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    ref={field.ref}
                    onValueChange={(value) => field.onChange(value === 'yes')}
                    defaultValue={defaultValue}
                    className="flex items-center mt-0 space-x-2"
                  >
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="yes" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.yes')}
                      </FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-1 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="no" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        {t('form.no')}
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          );
        }}
      />
    </div>
  );
};

export default UserForm;
