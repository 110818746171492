import api from '@/services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

const usePostAnnouncements = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: ['postAnnouncement'],
    mutationFn: ({ data }: { data: any }) => {
      return api.announcements.postAnnouncement(data);
    },
    onError: (error) => {
      const errorMessage = error.message;
      toast.error(t('toast.error', { errorMessage }));
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['announcements'] });
      toast.success(t('toast.announcementCreated'));
      navigate(-1);
    },
    retry: false
  });
};

export default usePostAnnouncements;
