import type { TBlockServiceSchedule } from '@/services/services';

interface ValueItem {
  status: string;
  slot: string;
}

interface Field {
  value: ValueItem[];
  onChange: (values: ValueItem[]) => void;
}

interface Props {
  field: Field;
  time: Date;
  availableDatesSchool?: Record<string, ValueItem[]>;
}

export function hasAvailable(values: ValueItem[] | undefined): boolean {
  return !!values?.some((value) => value.status === 'available');
}

export function getUpdatedValues(
  values: ValueItem[],
  checked: boolean | string,
  dateKey: string,
  availableDatesSchool?: TBlockServiceSchedule | null
): ValueItem[] {
  return values.map((value, idx) => {
    const isBlocked =
      availableDatesSchool?.[dateKey]?.[idx]?.status === 'blocked';
    if (isBlocked) {
      return value;
    }
    return {
      ...value,
      status: checked ? 'blocked' : 'available'
    };
  });
}
