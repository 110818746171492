import { Button } from '@/components/ui/button';
import useGetAnnouncements from '@/hooks/queries/announcements-api/useGetAnnouncements';
import useGetOrganizationFromCache from '@/hooks/queries/organization-api/useGetOrganizationFromCache';
import type { TAnnouncement } from '@/services/announcements';
import {
  type ColumnDef,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table';
import { EditIcon, PlusIcon } from 'lucide-react';
import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ErrorMessage from '../../error-message/ErrorMessage';
import AudienceCell from '../common/audience-cell/AudienceCell';
import { TanStackBasicTableTableComponent } from '../common/basic-table-component/TanStackBasicTableComponent';
import DateCell from '../common/date-cell/DateCell';
import EditCell from '../common/edit-cell/EditCell';

const AnnouncementsTable: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: announcements, isLoading, isError } = useGetAnnouncements();
  const organization = useGetOrganizationFromCache();

  const columns = useMemo<ColumnDef<TAnnouncement>[]>(
    () => [
      {
        id: 'title',
        accessorKey: 'title',
        header: t('announcements.title')
      },
      {
        id: 'startTime',
        accessorKey: 'startTime',
        header: t('announcements.startTime'),
        cell: ({ row }) => (
          <span className="flex text-center">
            <DateCell
              date={row.original.startTime}
              tz={organization?.tz ?? 'America/Argentina/Buenos_Aires'}
            />
          </span>
        )
      },
      {
        id: 'endTime',
        accessorKey: 'endTime',
        header: t('announcements.endTime'),
        cell: ({ row }) => {
          if (!row.original.endTime) {
            return null;
          }
          return (
            <span className="flex text-center">
              <DateCell
                date={row.original.endTime}
                tz={organization?.tz ?? 'America/Argentina/Buenos_Aires'}
              />
            </span>
          );
        }
      },
      {
        id: 'audience',
        accessorKey: 'audience',
        header: t('announcements.audience'),
        cell: ({ row }) => <AudienceCell audience={row.original.audience} />
      },
      {
        id: 'createdAt',
        accessorKey: 'createdAt',
        header: t('announcements.createdAt'),
        cell: ({ row }) => (
          <span className="flex text-center">
            <DateCell
              date={row.original.createdAt}
              tz={organization?.tz ?? 'America/Argentina/Buenos_Aires'}
            />
          </span>
        )
      },
      {
        id: 'id',
        accessorKey: 'id',
        header: t('tables.common.id')
      },
      {
        id: 'actions-edit',
        enableHiding: false,
        // cell: ({ row }) => <EditCell id={row.original.id} />
        cell: ({ row }) => (
          <Link
            to={{
              pathname: `./edit/${row.original.id}`,
              search: `?announcement=${JSON.stringify(row.original)}`
            }}
            className="w-full"
          >
            <EditIcon />
          </Link>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const table = useReactTable({
    data: announcements ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    //pagination config
    getPaginationRowModel: getPaginationRowModel(),
    // onPaginationChange: setPagination,
    autoResetPageIndex: false,
    //sort config
    enableMultiSort: false,
    // onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      // sorting,
      // pagination,
      columnVisibility: {
        id: false // Oculta la columna "id"
      }
    }
  });

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 py-4">
        <Button
          onClick={() => navigate('./create')}
          className="flex items-center gap-2"
        >
          <PlusIcon className="w-5" />
          <p>{t('form.create')}</p>
        </Button>
      </div>
      <TanStackBasicTableTableComponent table={table} isLoading={isLoading} />
    </>
  );
};

export default AnnouncementsTable;
