import { ReservationInterface } from '@/components/common/tables/tables.types';
import api from '@/services/api';
import { TConsumer } from '@/services/consumer';
import { TGetReservations } from '@/services/reservations';
import { ReservationStatus } from '@/utils/reservations';
import { QueryParams } from '@/utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

const usePatchReservation = ({
  disabledRefetch = false
}: {
  disabledRefetch?: boolean;
} = {}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  function getReservationStatusName(status: string): string | undefined {
    return Object.keys(ReservationStatus).find((key) =>
      status === 'complete'
        ? key === ReservationStatus.Completed
        : key.toLocaleLowerCase() === status
    );
  }

  function updateConsumers(
    consumers: TConsumer[],
    status: string
  ): TConsumer[] {
    return consumers.map((consumer) => ({
      ...consumer,
      additionalData: {
        ...consumer.additionalData,
        isConcurrentAbsent:
          status === 'complete'
            ? false
            : consumer.additionalData?.isConcurrentAbsent
      }
    }));
  }

  function transformReservationData(
    data: ReservationInterface,
    reservationId: string,
    status: string
  ): ReservationInterface {
    if (data.id !== reservationId) {
      return data;
    }

    return {
      ...data,
      reservationStatus: {
        name: getReservationStatusName(status) as any
      },
      consumers: updateConsumers(data.consumers, status)
    };
  }

  function updateQueryData(
    oldData: ReservationInterface[] | TGetReservations | undefined,
    reservationId: string,
    status: string
  ): ReservationInterface[] | TGetReservations | undefined {
    if (!oldData) return oldData;

    if (Array.isArray(oldData)) {
      return oldData.map((data) =>
        transformReservationData(data, reservationId, status)
      );
    }

    return {
      ...oldData,
      results: oldData.results.map((data) =>
        transformReservationData(data, reservationId, status)
      )
    };
  }

  return useMutation({
    mutationKey: ['patchReservation'],
    mutationFn: ({
      status,
      reservationId,
      data,
      query
    }: {
      status: string;
      reservationId: string;
      data?: any;
      query?: QueryParams;
    }) => {
      if (disabledRefetch) {
        queryClient.setQueriesData(
          {
            predicate: (query) =>
              query.queryKey[0] === 'reservation' ||
              query.queryKey[0] === 'reservations'
          },
          (oldData: ReservationInterface[] | TGetReservations | undefined) => {
            return updateQueryData(oldData, reservationId, status);
          }
        );
      }
      return api.reservations.patchReservation(
        { status, reservationId, query },
        data
      );
    },
    onError: (error) => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === 'reservation' ||
          query.queryKey[0] === 'reservations'
      });
      const errorMessage = error.response?.data.message;
      console.log(error);
      toast.error(t('toast.error', { errorMessage }), { duration: 5000 });
    },
    onSuccess: () => {
      if (!disabledRefetch) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey[0] === 'reservation' ||
            query.queryKey[0] === 'reservations'
        });
      }
    },

    retry: false
  });
};

export default usePatchReservation;
