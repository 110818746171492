import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import useFormattedDate from '@/hooks/useFormattedDate';
import type { TUsers } from '@/services/user';
import { useRootStore } from '@/stores/root-store';
import type { StepService } from '@/stores/slices/step-service/step-service.types';
import { getTimeZone } from '@/utils/dates';
import { currencyFormat } from '@/utils/utils';
import {
  Building,
  Calendar,
  DollarSign,
  Loader,
  MapPin,
  User
} from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import type { NewBookingSummaryModalProps } from './NewBookingSummaryModal.types';

const NewBookingSummaryModal: React.FC<NewBookingSummaryModalProps> = ({
  open,
  onOpenChange,
  organization,
  isPending,
  user,
  onSubmit
}) => {
  const { t } = useTranslation();
  const stepServicesData: Required<StepService> | null = useRootStore(
    (store) => store.stepServicesData as Required<StepService>
  );

  const { formattedDate } = useFormattedDate();

  const consumers = stepServicesData.consumers;
  const service = stepServicesData.service;
  const email = stepServicesData.email;

  const participantsName = stepServicesData?.consumers
    ?.map((consumer) => {
      if (typeof consumer === 'string') {
        const foundConsumer = (user as TUsers).consumers?.find(
          (c) => c.id === consumer
        );
        return `${foundConsumer?.firstName} ${foundConsumer?.lastName}`;
      }
      return `${consumer.firstName} ${consumer.lastName}`;
    })
    .join(', ');

  const reservationDate = formattedDate({
    date: getTimeZone(
      stepServicesData?.firstReservationStartTime,
      organization.tz
    ),
    dateFormat: 'dateTime'
  });

  const total = service.price * consumers.length;
  const recervationPct = service.reservationPct;
  const deposit = total * (recervationPct / 100);

  const summaryValues = [
    {
      title: t('newBooking.summaryModal.fields.organization'),
      text: organization.name,
      icon: Building
    },
    {
      title: t('newBooking.summaryModal.fields.address'),
      text: organization.address,
      icon: MapPin
    },
    {
      title: t('newBooking.summaryModal.fields.service'),
      text: service.name,
      icon: DollarSign
    },
    {
      title: `${t('newBooking.summaryModal.fields.participants')} (${consumers.length})`,
      text: participantsName,
      icon: User
    },
    {
      title: t('newBooking.summaryModal.fields.firstReservation'),
      text: reservationDate,
      icon: Calendar
    },
    {
      title: t('newBooking.summaryModal.fields.deposit'),
      text: currencyFormat(deposit, organization.currency),
      icon: DollarSign
    },
    {
      title: t('newBooking.summaryModal.fields.totalPrice'),
      text: currencyFormat(total, organization.currency),
      icon: DollarSign
    }
  ];

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t('newBooking.summaryModal.title')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {user
              ? t('newBooking.summaryModal.description')
              : t('newBooking.summaryModal.descriptionNewUser')}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <ScrollArea className="h-[50vh]">
          <div className="space-y-4 my-4 roundend-md p-4 bg-accent">
            {summaryValues.map((item, index) => (
              <div
                className="flex flex-col justify-between"
                key={`${index}-${item.title}`}
              >
                <p className="font-bold flex">
                  {item.icon && <item.icon className="w-5 mr-2" />}
                  {item.title}:
                </p>
                <p className="mt-2 text-sm">{item.text}</p>
              </div>
            ))}
          </div>
          <AlertDialogDescription>
            <Trans
              i18nKey="newBooking.summaryModal.emailNotice"
              values={{ email }}
              components={{ strong: <strong style={{ color: '#0892b5' }} /> }}
            />
          </AlertDialogDescription>
          <AlertDialogFooter className="mt-5 mb-1 flex-col">
            <Button disabled={isPending} onClick={onSubmit}>
              {isPending ? (
                <Loader className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                t('common.confirm')
              )}
            </Button>
            <AlertDialogCancel>{t('common.cancel')}</AlertDialogCancel>
          </AlertDialogFooter>
        </ScrollArea>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default NewBookingSummaryModal;
