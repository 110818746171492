export default function MedicalHistorySection({
  t,
  additionalData
}: {
  t: any;
  additionalData: any;
}) {
  return (
    <div className="mb-8">
      <h2 className="mb-4 text-xl font-semibold">
        {t('activity.student.backgroundAndTreatments')}
      </h2>
      <div className="space-y-2">
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.periodicTreatment')}:
          </p>
          <p>
            {additionalData?.diseases[0]?.value ? t('form.yes') : t('form.no')}
          </p>
          <p>{`${additionalData?.diseases[0]?.value ? t('activity.student.periodicTreatmentDetails') + ': ' + additionalData?.diseases[0]?.answer : ''}`}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.recentHospitalization')}:
          </p>
          <p>
            {additionalData?.diseases[1]?.value ? t('form.yes') : t('form.no')}
          </p>
          <p>{`${additionalData?.diseases[1]?.value ? t('activity.student.hospitalizationReason') + ': ' + additionalData?.diseases[1]?.answer : ''}`}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.allergy')}:
          </p>
          <p>
            {additionalData?.diseases[2]?.value ? t('form.yes') : t('form.no')}
          </p>
          <p>{`${additionalData?.diseases[2]?.value ? t('activity.student.allergyDetails') + ': ' + additionalData?.diseases[2]?.answer : ''}`}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.medicalTreatment')}:
          </p>
          <p>
            {additionalData?.treatments[0]?.value
              ? t('form.yes')
              : t('form.no')}
          </p>
          <p>{`${additionalData?.treatments[0]?.value ? t('activity.student.medicalTreatmentDetails') + ': ' + additionalData?.treatments[0]?.answer : ''}`}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.physicalLimitation')}:
          </p>
          <p>
            {additionalData?.treatments[1]?.value
              ? t('form.yes')
              : t('form.no')}
          </p>
          <p>{`${additionalData?.treatments[1]?.value ? t('activity.student.physicalLimitationDetails') + ': ' + additionalData?.treatments[1]?.answer : ''}`}</p>
        </div>
        <div>
          <p className="text-sm text-muted-foreground">
            {t('activity.student.otherHealthIssues')}:
          </p>
          <p>
            {additionalData?.treatments[2]?.value
              ? t('form.yes')
              : t('form.no')}
          </p>
          <p>{`${additionalData?.treatments[2]?.value ? t('activity.student.otherHealthIssuesDetails') + ': ' + additionalData?.treatments[2]?.answer : ''}`}</p>
        </div>
      </div>
    </div>
  );
}
