import type React from 'react';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { getTimeZone } from '@/utils/dates';

interface DateCellProps {
  date: Date | string | number;
  tz?: string;
}

const DateCell: React.FC<DateCellProps> = ({ date, tz }) => {
  const adjustedDate = getTimeZone(
    date,
    tz ?? 'America/Argentina/Buenos_Aires'
  );
  return (
    <span>{format(adjustedDate, "dd/MM/yy HH:mm'hs'", { locale: es })}</span>
  );
};

export default DateCell;
