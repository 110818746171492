import { Badge } from '@/components/ui/badge';
import useGetOrganizationServices from '@/hooks/queries/services-api/useGetOrganizationServices';
import useDateRange from '@/hooks/useDateRange';
import { ageGroups } from '@/lib/utils';
import type { TServices } from '@/services/services';
import { ReservationStatus } from '@/utils/reservations';
import type { Table } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { Loader } from 'lucide-react';
import { type ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmojiLegendModal from '../../modals/emoji-legend-modal/EmojiLegendModal';
import TableColumnsToggle from '../../table-columns-toggle/TableColumnsToggle';
import { TableFacetedFilter } from '../../table-faceted-filter/TableFacetedFilter';
import TableFilters from '../../table-filters/TableFilters';
import TableSearchInput from '../common/table-search-input/TableSearchInput';
import GroupFilter from './GroupFilter';

type TableToolBarProps<TData> = Readonly<{
  table: Table<TData>;
  filtering: string;
  setFiltering: (value: string) => void;
  isFetching: boolean;
}>;

export default function TableToolBar<TData>({
  table,
  filtering,
  setFiltering,
  isFetching
}: TableToolBarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  const { t } = useTranslation();
  const { isPending } = useGetOrganizationServices<TServices[]>();

  const [search, setSearch] = useState(filtering || '');

  const debounceFn = useCallback(
    debounce((value) => {
      setFiltering(value);
    }, 750),
    []
  );

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debounceFn(event.target.value);
  };

  const groupOptions = Object.keys(ageGroups).map((group, index) => ({
    label: t(`tables.group.${index + 1}`),
    value: group
  }));

  const statusesOptions = [
    {
      value: ReservationStatus.Completed,
      label: t('common.status.present'),
      icon: () => (
        <Badge
          variant={'Pending'}
          className="w-4 h-4 mx-1 rounded-full bg-primary"
        />
      )
    },
    {
      value: ReservationStatus.Absent,
      label: t('common.status.absent'),
      icon: () => (
        <Badge
          variant={'Accepted'}
          className="w-4 h-4 mx-1 bg-red-500 rounded-full"
        />
      )
    },
    {
      value: ReservationStatus.Accepted,
      label: t('common.status.pending'),
      icon: () => (
        <Badge
          variant={'Rejected'}
          className="w-4 h-4 mx-1 bg-gray-400 rounded-full"
        />
      )
    }
  ];

  return (
    <div className="grid grid-cols-[1fr,1fr,auto] grid-rows-[auto,auto] items-center sm:grid-cols-[auto,auto,1fr,auto] sm:grid-rows-1 gap-4">
      <div className="order-1 sm:order-1">
        <TableFilters filterActive={isFiltered}>
          <div className="flex flex-col items-start w-full gap-2">
            {table.getColumn('sale.saleConsumers.consumer.additionalData') && (
              <GroupFilter
                column={table.getColumn(
                  'sale.saleConsumers.consumer.additionalData'
                )}
                title={t('activity.student.group')}
                options={groupOptions}
              />
            )}
            {table.getColumn('reservationStatus.name') && (
              <TableFacetedFilter
                column={table.getColumn('reservationStatus.name')}
                title={t('tables.servicesSold.statuses')}
                options={statusesOptions}
                disabled={isPending}
              />
            )}
          </div>
        </TableFilters>
      </div>
      <div className="order-2 w-full sm:order-4 sm:w-auto">
        <TableColumnsToggle table={table} />
      </div>
      <div className="order-3 sm:order-2 ">
        <EmojiLegendModal />
      </div>
      <div className="order-4 col-span-3 sm:col-span-1 sm:order-3 sm:justify-self-end">
        <TableSearchInput value={search ?? ''} onChange={onSearch} />
        {search && isFetching && (
          <Loader className="text-gray-600 animate-spin" />
        )}
      </div>
    </div>
  );
}
