import { TBlockServiceSchedule } from '@/services/services';
import { parse } from 'date-fns';

export function getDatesByAvailability(availableDates: TBlockServiceSchedule) {
  const fullAvailable = [];
  const fullBlock = [];
  const semiAvailable = [];
  const semiBlock = [];
  for (const date in availableDates) {
    const formatedDate = parse(date, 'dd-MM-yyyy', new Date());
    const slotsInDay = availableDates[date];
    let availableDatesCount = 0;
    const amountOfSlots = slotsInDay.length;
    slotsInDay.forEach((item) => {
      if (item.status === 'available') availableDatesCount++;
    });
    if (amountOfSlots === availableDatesCount) {
      fullAvailable.push(formatedDate);
    } else if (availableDatesCount === 0) {
      fullBlock.push(formatedDate);
    } else if (availableDatesCount > amountOfSlots / 2) {
      semiAvailable.push(formatedDate);
    } else {
      semiBlock.push(formatedDate);
    }
  }
  return { fullAvailable, fullBlock, semiAvailable, semiBlock };
}

export function getDefaultMonth(availableDates: TBlockServiceSchedule) {
  return Object.keys(availableDates)
    .map((date) => {
      return parse(date, 'dd-MM-yyyy', new Date());
    })
    .sort(function (a, b) {
      return a.valueOf() - b.valueOf();
    })[0];
}

export const MODIFIERS_STYLES_AVAILABILITY = {
  fullAvailable: { backgroundColor: '#D4F5DD', color: 'black' },
  fullBlock: { backgroundColor: '#F2CDC4', color: 'black' },
  semiAvailable: { backgroundColor: '#ffffba', color: 'black' },
  semiBlock: { backgroundColor: '#F2DEA2', color: 'black' },
  selectedDay: { border: '2px solid black' }
};
