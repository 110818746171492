import IdentificationTypeSelect from '@/components/common/input/identification-types-select/IdentificationTypeSelect';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import type React from 'react';
import { useFieldArray, type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const ParentsForm: React.FC<{ form: UseFormReturn<any, any, undefined> }> = ({
  form
}) => {
  const { t } = useTranslation();
  const { fields } = useFieldArray({
    control: form.control,
    name: 'parents'
  });

  return (
    <div className="grid gap-6 md:grid-cols-2">
      {fields.map((field, index) => {
        const isError = Boolean(
          (form.formState.errors?.parents as any)?.[index]
        );
        return (
          <div key={`parent-${index}-${field.id}`}>
            <p
              className={cn(
                isError && 'text-destructive',
                'font-semibold my-5'
              )}
            >
              {t('parents', { number: index + 1 })}
            </p>
            <div
              className="flex flex-col gap-6 "
              key={'participant-participant'}
            >
              <FormField
                control={form.control}
                name={`parents.${index}.firstName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.firstName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.lastName`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.lastName')}
                      </FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.connection`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('connection')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          placeholder={t('connectionPlaceholder')}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.phone`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('phone')}</FormLabel>
                      <FormControl>
                        <Input
                          type="tel"
                          {...field}
                          placeholder={t('common.placeholder.phone')}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.identification.type`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <IdentificationTypeSelect
                        defaultValue={field?.value}
                        onValueChange={(value) => {
                          field.onChange(value);
                        }}
                        field={field}
                      />
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.identification.number`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t('stepParticipants.form.identificationNumber')}
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('stepParticipants.form.id')}
                          {...field}
                          value={field?.value || ''}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                control={form.control}
                name={`parents.${index}.email`}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>{t('stepParticipants.form.mail')}</FormLabel>
                      <FormControl>
                        <Input type="tel" {...field} />
                      </FormControl>
                      <FormMessage />
                      {index === 0 && (
                        <FormDescription>
                          {t('newBooking.formParticipantData.mail_description')}
                        </FormDescription>
                      )}
                    </FormItem>
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ParentsForm;
