import { TActiveDays } from '@/services/organization';
import { z } from 'zod';

const isTimeOverlapping = (
  existingRanges: number[][],
  newRange: number[]
): boolean => {
  return existingRanges.some(([start, end]) => {
    return newRange[0] < end && newRange[1] > start;
  });
};

const ActiveHoursSchema = (hoursRanges: TActiveDays[keyof TActiveDays]) => {
  return z
    .object({
      startTime: z.string().min(1, 'schedule.noChosenStartTime'),
      endTime: z.string().min(1, 'schedule.noChosenEndTime'),
      scheduleConflict: z.optional(z.boolean()) // Custom error message
    })
    .refine(
      (data) => {
        const startTime = parseInt(data.startTime.replace(':', ''), 10);
        const endTime = parseInt(data.endTime.replace(':', ''), 10);

        return startTime < endTime;
      },
      {
        message: 'schedule.invertedActiveDays',
        path: ['scheduleConflict']
      }
    )
    .refine(
      (data) => {
        const startTime = parseInt(data.startTime.replace(':', ''), 10);
        const endTime = parseInt(data.endTime.replace(':', ''), 10);

        return !isTimeOverlapping(hoursRanges, [startTime, endTime]);
      },
      {
        message: 'schedule.overlappingActiveDays',
        path: ['scheduleConflict']
      }
    );
};

export default ActiveHoursSchema;

type TForm = ReturnType<typeof ActiveHoursSchema>;
export type ActiveHoursValues = z.infer<TForm>;
