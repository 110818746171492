import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const servicesCategorySchema = () => {
  return z.object({
    name: z
      .string({
        required_error: 'schemas.serviceCategory.nameRequired'
      })
      .min(4, 'schemas.serviceCategory.nameMin')
      .max(100, 'schemas.serviceCategory.nameMax'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.medium,
        `schemas.serviceCategory.descriptionMax|{"maxLength": ${DESCRIPTION_MAX_LENGTH.medium}}`
      )
      .optional()
  });
};

export default servicesCategorySchema;
