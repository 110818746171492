import AlertSection from '@/components/common/alert/AlertSection';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { TimePicker } from '@/components/ui/time-picker';
import formBookingDatepickerSchema from '@/schemas/formBookingDatepicker';
import { useRootStore } from '@/stores/root-store';
import { getTimeZone, getTimestampUTC } from '@/utils/dates';
import {
  SCHEDULE_STATUS,
  findSchedule as getSchedule,
  sortSchedule,
  validateCustomTime
} from '@/utils/schedule';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMonths, format } from 'date-fns';
import { CheckCircle, Circle, Loader, XCircle } from 'lucide-react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { z } from 'zod';
import type { FormBookingDatePickerProps } from './formBookingDatePicker.types';

export type TForm = z.input<ReturnType<typeof formBookingDatepickerSchema>>;

const FormBookingDatePicker: React.FC<FormBookingDatePickerProps> = ({
  availableDates,
  calendarPaginateNavigation,
  organization
}) => {
  const { t } = useTranslation();
  const addStep = useRootStore((store) => store.addStep);
  const removeStep = useRootStore((store) => store.removeStep);
  const currentStep = useRootStore((store) => store.currentStep);
  const stepServicesData = useRootStore((store) => store.stepServicesData);
  const loadStepServicesData = useRootStore(
    (store) => store.loadStepServicesData
  );

  const [timeIsLoading, setTimeIsLoading] = useState(false);
  const participants = stepServicesData?.participants ?? 1;

  // get the available dates and sort them
  const schedule = sortSchedule(availableDates);
  const findSchedule = (date: Date) => getSchedule({ date, schedule });
  const isInvalidCustomTime = (date: Date) =>
    validateCustomTime({ date, schedule });
  const hasSchedule = !!schedule.length;

  const defaultDate = () => {
    const isValidCurrentDate = stepServicesData?.firstReservationStartTime;
    if (isValidCurrentDate) {
      return getTimeZone(isValidCurrentDate, organization.tz);
    }
    if (hasSchedule) {
      return schedule[0].date;
    }
    return undefined;
  };

  // Determine the month range for the calendar, and block navigation when is fetching date
  const toMonth = addMonths(new Date(), 24);

  const defaultTime = () => {
    const time = stepServicesData?.firstReservationStartTime;
    if (time && stepServicesData?.timeTabs !== 'custom') {
      const parsedTime = getTimeZone(time, organization.tz);
      const parsedFirstReservationStartTime = format(parsedTime, 'HH:mm');
      const slots = findSchedule(parsedTime)?.slots;
      return slots?.find((slot) =>
        slot.from.startsWith(parsedFirstReservationStartTime)
      );
    }
  };

  // Initializing useForm hook with the schema and default values
  const form = useForm<TForm>({
    resolver: zodResolver(formBookingDatepickerSchema(isInvalidCustomTime)),
    defaultValues: {
      date: defaultDate(),
      time: defaultTime(),
      timeTabs: stepServicesData?.timeTabs || 'default'
    }
  });

  const handleDateChange = () => {
    // Hide the time field before showing it again to force re-render
    setTimeIsLoading(true);
    setTimeout(() => {
      setTimeIsLoading(false);
    }, 300); // Using a delay of 0ms for quick toggle
  };

  // Function to handle form submission
  function onSubmit(values: TForm) {
    let time;
    if (typeof values.time !== 'undefined') {
      time = values.time.from; // Gets the first part of the time range
    }

    const firstReservationStartTime = getTimestampUTC({
      date: values.date,
      time,
      tz: organization.tz
    });
    if (firstReservationStartTime) {
      loadStepServicesData({
        firstReservationStartTime,
        timeTabs: values.timeTabs
      });
      addStep(currentStep);
    }
  }

  const dateFormValue = form.getValues('date');
  const tabs = form.getValues().timeTabs;

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h2 className="mb-6 font-bold">{t('newBooking.stepDateTitle')}</h2>
        <div className="flex flex-row justify-between px-5 py-2 align-middle rounded-sm bg-accent">
          <h3 className="font-bold ">{stepServicesData?.service?.name}</h3>
          <p className="">
            {participants} {t('newBooking.participant')}
            {participants > 1 ? 's' : ''}
          </p>
        </div>
        <div className="flex flex-col gap-4 my-8">
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl className="max-w-lg mx-auto my-0">
                    <Calendar
                      classNames={{
                        row: 'flex w-full mt-2 justify-around',
                        head_row: 'flex justify-around',
                        months:
                          'flex flex-col space-y-4 sm:space-x-4 sm:space-y-0'
                      }}
                      mode="single"
                      selected={field.value}
                      onSelect={(value) => {
                        if (value) {
                          handleDateChange();
                          form.reset({
                            ...form.getValues(),
                            date: value,
                            time: undefined
                          });
                        }
                      }}
                      toMonth={toMonth}
                      defaultMonth={defaultDate()}
                      onMonthChange={(date) => {
                        const curretMonthPaginated =
                          calendarPaginateNavigation.date;

                        if (curretMonthPaginated.getTime() < date.getTime()) {
                          calendarPaginateNavigation.goToNextMonth();
                        } else {
                          calendarPaginateNavigation.goToPreviousMonth();
                        }

                        form.reset({
                          ...form.getValues(),
                          date: undefined,
                          time: undefined
                        });
                      }}
                      disabled={(date) => {
                        if (!availableDates) return true;
                        return !schedule.some(
                          (d) => date.getTime() === d.date.getTime()
                        );
                      }}
                      initialFocus
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {timeIsLoading && !!dateFormValue ? (
            <div>
              <FormLabel className="font-bold">
                {t('newBooking.stepDateRadioTitle')}
              </FormLabel>
              <div className="flex items-center justify-center my-4 min-h-24">
                <Loader className="animate-spin" />
              </div>
            </div>
          ) : (
            <Tabs
              value={tabs}
              defaultValue="default"
              onValueChange={(value) => {
                form.reset({
                  date: dateFormValue,
                  timeTabs: value,
                  time: value === 'default' ? defaultTime() : undefined
                });
              }}
            >
              <TabsList className="grid w-full grid-cols-2">
                <TabsTrigger value="default">
                  {t('common.defaultDates')}
                </TabsTrigger>
                <TabsTrigger value="custom">
                  {t('common.customDates')}
                </TabsTrigger>
              </TabsList>
              <TabsContent value="default">
                {/* add validation to prevent modify the information when is disnmounting */}
                {tabs === 'default' ? (
                  <FormField
                    control={form.control}
                    name="time"
                    render={({ field }) => {
                      const slots = dateFormValue
                        ? findSchedule(dateFormValue)?.slots
                        : undefined;
                      return (
                        <FormItem>
                          <FormLabel className="font-bold">
                            {t('newBooking.stepDateRadioTitle')}
                          </FormLabel>
                          <FormControl>
                            {slots ? (
                              <FormItem className="grid grid-cols-1 gap-3 space-y-0 sm:grid-cols-2 md:grid-cols-3">
                                {slots?.map((slot) => {
                                  const from = slot.from;
                                  const to = slot.to;

                                  let isSelected = false;
                                  if (typeof field.value !== 'string') {
                                    isSelected = field.value?.from === from;
                                  }

                                  const isAvailable =
                                    slot.status === SCHEDULE_STATUS.available;
                                  const value = `${from}-${to}`;

                                  if (!isAvailable) {
                                    return (
                                      <Button
                                        type="button"
                                        key={value}
                                        className="flex flex-col items-start h-20 border-red-400"
                                        variant={'outline'}
                                        disabled
                                      >
                                        <div className="flex">
                                          <XCircle
                                            className="text-red-400"
                                            width={20}
                                          />
                                          <p className="ml-3 text-base">
                                            {value}
                                          </p>
                                        </div>
                                        <p className="text-red-600">
                                          {slot.statusDetail
                                            ? slot.statusDetail
                                            : t('newBooking.unAvailable')}
                                        </p>
                                      </Button>
                                    );
                                  }
                                  return (
                                    <Button
                                      type="button"
                                      key={value}
                                      onClick={() => {
                                        field.onChange(slot);
                                      }}
                                      variant={
                                        isSelected ? 'default' : 'outline'
                                      }
                                      className="flex justify-start h-20"
                                    >
                                      <div>
                                        {isSelected ? (
                                          <CheckCircle width={20} />
                                        ) : (
                                          <Circle
                                            className="text-zinc-400"
                                            width={20}
                                          />
                                        )}
                                      </div>
                                      <p className="ml-3 text-base">{value}</p>
                                    </Button>
                                  );
                                })}
                              </FormItem>
                            ) : null}
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                ) : null}
              </TabsContent>
              <TabsContent value="custom">
                <AlertSection
                  variant="warning"
                  text={t('newBooking.alertCustomTime')}
                />
                {/* add validation to prevent modify the information when is disnmounting */}
                {tabs === 'custom' ? (
                  <FormField
                    control={form.control}
                    name="date"
                    render={({ field }) => {
                      return (
                        <div className="flex flex-col pt-3 space-y-3 place-items-center">
                          <TimePicker
                            date={field.value}
                            setDate={(date) => field.onChange(date)}
                          />
                          <FormMessage />
                        </div>
                      );
                    }}
                  />
                ) : null}
              </TabsContent>
            </Tabs>
          )}
        </div>

        <div className="flex flex-col gap-3">
          <Button type="submit">{t('newBooking.continue')}</Button>
          <Button
            onClick={() => {
              removeStep(currentStep);
              loadStepServicesData({ firstReservationStartTime: undefined });
            }}
            variant={'outline'}
          >
            {t('newBooking.goBack')}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default FormBookingDatePicker;
