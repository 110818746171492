import ActiveDays from '@/components/common/active-periods/ActiveDays';
import ActiveMonths from '@/components/common/active-periods/ActiveMonths';
import { Button } from '@/components/ui/button';
import usePatchServiceSchedule from '@/hooks/queries/services-api/usePatchServiceSchedule';
import { TActiveDays, TActiveMonths, TSchedule } from '@/services/organization';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  serviceId: string;
  defaultSchedule: TSchedule;
}

const ServiceSchedule = ({ serviceId, defaultSchedule }: Props) => {
  const [dateRanges, setDateRanges] = useState<TActiveMonths>(
    defaultSchedule.activeMonths
  );
  const [hoursRanges, setHoursRanges] = useState<TActiveDays>(
    defaultSchedule.activeDays
  );
  const { mutate } = usePatchServiceSchedule();
  const { t } = useTranslation();

  const handleOnSubmit = () => {
    mutate({
      payload: {
        activeDays: hoursRanges,
        activeMonths: dateRanges
      },
      serviceId
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-2">
        <ActiveMonths dateRanges={dateRanges} setDateRanges={setDateRanges} />
        <ActiveDays hoursRanges={hoursRanges} setHoursRanges={setHoursRanges} />
      </div>
      <Button className="self-end" onClick={handleOnSubmit}>
        {t('common.save')}
      </Button>
    </div>
  );
};

export default ServiceSchedule;
