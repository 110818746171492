import Navbar from '@/components/section/navbar/navbar';
import { AppSidebar } from '@/components/section/sidebar/AppSidebar';
import { SidebarProvider } from '@/components/ui/sidebar';
import useAuth from '@/hooks/useAuth';
import { Outlet } from 'react-router-dom';
import CallbackPage from './Callback';
import { Announcements } from '@/components/section/announcements-audience/AnnouncementsAudience';
import useGetUser from '@/hooks/queries/users-api/useGetUser';
import useGetOrganization from '@/hooks/queries/organization-api/useGetOrganization';

const RootAsUserLogged = () => {
  const { isLoading: isLoadingUser } = useGetUser();
  const { isLoading: isLoadingOrganization } = useGetOrganization();

  if (isLoadingUser || isLoadingOrganization) {
    return <CallbackPage />;
  }

  return (
    <SidebarProvider>
      <AppSidebar />
      <main className="relative flex-1 overflow-y-auto">
        <Navbar />
        <div className="flex flex-col gap-4 p-4 mt-14 md:mt-[60px] md:px-6 md:py-6 bg-base-200">
          <Announcements />
          <div>
            <Outlet />
          </div>
        </div>
      </main>
    </SidebarProvider>
  );
};

const Root = () => {
  const { tokenIsPending } = useAuth();

  if (tokenIsPending) {
    return <CallbackPage />;
  }

  return <RootAsUserLogged />;
};
export default Root;
