import { DESCRIPTION_MAX_LENGTH } from '@/utils/utils';
import { z } from 'zod';

const optionSchema = z.object({
  label: z.string(),
  value: z.string(),
  disable: z.boolean().optional()
});

const inventoryCategorySchema = () => {
  return z.object({
    name: z
      .string({
        required_error: 'schemas.inventoryCategory.nameRequired'
      })
      .min(4, 'schemas.inventoryCategory.nameMin')
      .max(100, 'schemas.inventoryCategory.nameMax'),
    description: z
      .string()
      .max(
        DESCRIPTION_MAX_LENGTH.medium,
        `schemas.inventoryCategory.descriptionMax|{"maxLength": ${DESCRIPTION_MAX_LENGTH.medium}}`
      )
      .optional(),
    sports: z.array(optionSchema).optional()
  });
};

export default inventoryCategorySchema;
