import api from '@/services/api';
import { useQuery } from '@tanstack/react-query';

const useGetAnnouncements = () => {
  return useQuery({
    queryKey: ['announcements'],
    queryFn: () => api.announcements.getAnnouncements()
  });
};
export default useGetAnnouncements;
