import ErrorMessage from '@/components/common/error-message/ErrorMessage';
import GoBack from '@/components/common/go-back/GoBack';
import NoData from '@/components/common/no-data/NoData';
import FormUploadConsumerImage from '@/components/forms/form-uploadConsumer-image/FormUploadConsumerImage';
import FormUserUpdate from '@/components/forms/form-user-update/FormUserUpdate';
import type { TForm } from '@/components/forms/form-user-update/FormUserUpdate.type';
import { Skeleton } from '@/components/ui/skeleton';
import useGetConsumerById from '@/hooks/queries/consumer-api/useGetConsumerById';
import useUpdateUser from '@/hooks/queries/users-api/useUptadeUser';
import type { TConsumer } from '@/services/consumer';
import { getChangedValues } from '@/utils/forms';
import { useNavigate, useParams } from 'react-router-dom';
import type { z } from 'zod';

const Users = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: user, isPending, isError } = useGetConsumerById(id);
  const { mutate, isPending: isSending } = useUpdateUser();

  // por que hacemos esto?
  if (typeof id !== 'string') {
    navigate('users');
    return;
  }
  if (isPending) {
    return (
      <div className="grid grid-cols-2">
        <Skeleton className="flex flex-col w-full h-full gap-10 px-4 py-8 ">
          {[...Array(5)].map((item, index) => (
            <div className="flex flex-col gap-2" key={`${index}-${item}`}>
              <Skeleton className="w-20 h-4 bg-slate-200" />
              <Skeleton className="w-full h-10 bg-slate-200" />
            </div>
          ))}
        </Skeleton>
        <Skeleton className="flex flex-col w-full h-full gap-10 px-4 py-8 ">
          {[...Array(5)].map((item, index) => (
            <div className="flex flex-col gap-2" key={`${index}-${item}`}>
              <Skeleton className="w-20 h-4 bg-slate-200" />
              <Skeleton className="w-full h-10 bg-slate-200" />
            </div>
          ))}
        </Skeleton>
      </div>
    );
  }
  if (isError) {
    return <ErrorMessage />;
  }
  if (!user) {
    return <NoData />;
  }
  return (
    <>
      <GoBack />
      <FormUserUpdate
        user={user}
        onSubmit={(data: z.infer<TForm>) => {
          const changes = getChangedValues<Partial<TConsumer>>(user, {
            ...data,
            birthdate: data.birthdate?.toISOString()
          });
          mutate({
            id: id,
            body: changes
          });
        }}
        isSending={isSending}
      />
      <FormUploadConsumerImage id={id} />
    </>
  );
};

export default Users;
