import { format } from 'date-fns';
import { TActiveMonths } from '@/services/organization';
import { useState } from 'react';

export type Months = keyof TActiveMonths;

const useActiveMonths = (
  dateRanges: TActiveMonths,
  setDateRanges: (value: TActiveMonths) => void
) => {
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);

  const openModal = () => setIsDateModalOpen(true);

  const closeModal = () => setIsDateModalOpen(false);

  const handleAddDateRange = (startDate: Date, endDate: Date) => {
    const startMonth = format(startDate, 'MMMM').toLowerCase() as Months;
    const endMonth = format(endDate, 'MMMM').toLowerCase() as Months;
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    const newDateRanges: TActiveMonths = { ...dateRanges };

    if (startMonth === endMonth) {
      const newRange: number[] = [startDay, endDay];
      newDateRanges[startMonth].push(newRange);
    } else {
      const startRange: number[] = [
        startDay,
        new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate()
      ];
      const endRange: number[] = [1, endDay];
      newDateRanges[startMonth].push(startRange);
      newDateRanges[endMonth].push(endRange);
    }

    setDateRanges(newDateRanges);
  };

  const handleRemoveDateRange = (month: Months, index: number) => {
    const newDateRanges = { ...dateRanges };
    newDateRanges[month] = newDateRanges[month].filter((_, i) => i !== index);
    setDateRanges(newDateRanges);
  };

  return {
    isDateModalOpen,
    openModal,
    closeModal,
    handleAddDateRange,
    handleRemoveDateRange
  };
};

export default useActiveMonths;
